import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Button, MultiSelect } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { changeRouter } from 'lib/utilities'

import { AmountPicker, DateInputWithPicker } from 'components/atoms'

export interface InvoiceFilterSectionProps {
  className?: string
  componentId?: string
  onClickReset?: () => void
}

export enum InvoiceTypes {
  'Invoice' = 1,
  'Credit' = 2
}

export enum InvoiceTypesLabels {
  INVOICE = 'Invoice',
  CREDIT = 'Credit'
}

export enum InvoiceStatus {
  'Paid' = 1,
  'Current' = 2,
  'Overdue' = 3,
  'Credit Note' = 4,
  'Processing' = 5
}

export enum InvoiceStatusLabels {
  PAID = 'Paid',
  CURRENT = 'Current',
  OVERDUE = 'Overdue',
  CREDIT_NOTE = 'Credit Note',
  PROCESSING = 'Processing'
}

export const InvoiceFilterSection: FC<InvoiceFilterSectionProps> = ({
  className,
  componentId,
  onClickReset
}: InvoiceFilterSectionProps) => {
  const InvoiceFilterSectionClasses = CN(
    'w-full h-fit flex flex-col p-6 bg-white rounded border border-[#D9D9D5] gap-y-6',
    className
  )
  const params = useSearchParams()
  const router = useRouter()

  const DATE_PICKER_PLACE_HOLDER = ''
  const STATUSES = 'statuses'
  const TYPES = 'types'
  const FROM = 'from'
  const TO = 'to'
  const MIN_PRICE = 'minPrice'
  const MAX_PRICE = 'maxPrice'
  const PAGE = 'page'
  const PAGE_SIZE = 'pageSize'
  const SEARCH = 'search'
  const SEARCH_BY = 'searchBy'

  const statusesParam = params.get(STATUSES) ?? undefined
  const typesParam = params.get(TYPES)
  const minPriceParam = params.get(MIN_PRICE) ?? undefined
  const maxPriceParam = params.get(MAX_PRICE) ?? undefined

  const [filterDateRange, setFilterDateRange] = useState<any>({
    startDate: null,
    endDate: null,
    key: null
  })

  const [filterButtonText, setFilterButtonText] = useState(DATE_PICKER_PLACE_HOLDER)

  const [minPrice, setMinPrice] = useState<number | undefined>(
    minPriceParam ? Number(minPriceParam) : undefined
  )
  const [maxPrice, setMaxPrice] = useState<number | undefined>(
    maxPriceParam ? Number(maxPriceParam) : undefined
  )
  const [invoiceStatus, setInvoiceStatus] = useState(
    statusesParam?.split(',').map(status => ({
      label: status,
      value: InvoiceStatus[status as keyof typeof InvoiceStatus]
    }))
  )

  const currentDate = new Date()
  const minDate = new Date(
    currentDate.getFullYear() - 7,
    currentDate.getMonth(),
    currentDate.getDate()
  )

  useEffect(() => {
    const tempStatus = statusesParam?.split(',').map((status: string) => ({
      label: status,
      value: InvoiceStatus[status as keyof typeof InvoiceStatus]
    }))
    setInvoiceStatus(tempStatus)
  }, [statusesParam])

  const [invoiceType, setInvoiceType] = useState(
    typesParam?.split(',').map(type => ({
      label: type,
      value: InvoiceTypes[type as keyof typeof InvoiceTypes]
    }))
  )

  const onResetClick = () => {
    onClickReset?.()
    setFilterDateRange({
      startDate: null,
      endDate: null,
      key: 'selection'
    })
    setFilterButtonText(DATE_PICKER_PLACE_HOLDER)
    setInvoiceType([])
    setMinPrice(undefined)
    setMaxPrice(undefined)
    changeRouter(router, STATUSES, 'Overdue', [
      FROM,
      TO,
      TYPES,
      MIN_PRICE,
      MAX_PRICE,
      PAGE,
      PAGE_SIZE,
      SEARCH_BY,
      SEARCH
    ])
  }

  useEffect(() => {
    if (!typesParam) {
      setInvoiceType([])
    }
    if (!minPriceParam && !maxPriceParam) {
      setMinPrice(undefined)
      setMaxPrice(undefined)
    }
  }, [params])

  return (
    <div className='flex flex-col gap-6'>
      <div className={InvoiceFilterSectionClasses}>
        <div className='flex w-full justify-between items-center gap-x-3'>
          <p className='font-700 text-base text-N-700'>Filters</p>
          <Button
            componentId={`${componentId}-filter-section-resetAllFilters`}
            iconBefore='nzsbi-cycle text-[24px]'
            size='md'
            appearance='link-gray'
            onClick={() => {
              onResetClick()
            }}>
            Reset All Filters
          </Button>
        </div>

        <div className={CN('grid grid-cols-1 md:!grid-cols-2 gap-4 lg:!grid-cols-4')}>
          <DateInputWithPicker
            label='Date'
            componentId='invoices-and-credits-date-range-picker'
            filterButtonText={filterButtonText}
            setFilterButtonText={setFilterButtonText}
            filterDateRange={filterDateRange}
            setFilterDateRange={setFilterDateRange}
            minDate={minDate}
            maxDate={currentDate}
          />

          <div className='w-full'>
            <MultiSelect
              className='w-full'
              label='Type'
              componentId={`${componentId}-invoice-type-select`}
              value={invoiceType}
              options={[
                { label: InvoiceTypesLabels.INVOICE, value: InvoiceTypes.Invoice },
                { label: InvoiceTypesLabels.CREDIT, value: InvoiceTypes.Credit }
              ]}
              onChange={(types: any) => {
                setInvoiceType(types)
                if (types?.length > 0) {
                  const typesValues = types.map((type: any) => type.label)
                  changeRouter(router, TYPES, typesValues.join(','), [PAGE])
                } else {
                  changeRouter(router, undefined, undefined, [TYPES, PAGE])
                }
              }}
            />
          </div>

          <div className='w-full'>
            <AmountPicker
              label='Amount'
              componentId='invoice-and-credit-amount-picker'
              minPrice={minPrice}
              maxPrice={maxPrice}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
            />
          </div>

          <div className='w-full'>
            <MultiSelect
              className='w-full'
              label='Status'
              componentId={`${componentId}-invoice-status-select`}
              value={invoiceStatus}
              options={[
                {
                  label: InvoiceStatusLabels.OVERDUE,
                  value: InvoiceStatus.Overdue
                },
                { label: InvoiceStatusLabels.PROCESSING, value: InvoiceStatus.Processing },
                { label: InvoiceStatusLabels.CURRENT, value: InvoiceStatus.Current },
                { label: InvoiceStatusLabels.PAID, value: InvoiceStatus.Paid },
                { label: InvoiceStatusLabels.CREDIT_NOTE, value: InvoiceStatus['Credit Note'] }
              ]}
              onChange={(statuses: any) => {
                setInvoiceStatus(statuses)
                if (statuses?.length > 0) {
                  const statusValues = statuses.map((status: any) => status.label)
                  changeRouter(router, STATUSES, statusValues.join(','), [PAGE])
                } else {
                  changeRouter(router, undefined, undefined, [STATUSES, PAGE])
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceFilterSection
