import { useMutation } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IStatementAvailabilityResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IStatementAvailability
}

export interface IStatementAvailability {
  fileName: string
  isAvailable: boolean
}

export const getStatementAvailability = async (statementName: string) => {
  const response = await httpGET({
    url: `statement/GetStatementFile/${statementName}`
  })
  return response?.data as IStatementAvailabilityResponse
}

export const useGetStatementAvailability = () => {
  return useMutation({
    mutationFn: getStatementAvailability
  })
}
