import { useMutation, useQueryClient } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IAddURLRedirectionRequest {
  oldUrl: string
  newUrl: string
  httpStatusCode: number
  preserveQueryString: boolean
  subPathMapping: boolean
  preservePathSuffix: boolean
}

export interface IAddURLRedirectionResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export const addURLRedirection = async (req: IAddURLRedirectionRequest, context?: any) => {
  const { data } = await httpPOST({
    url: `seo/add/redirection`,
    body: req,
    context
  })

  return data as IAddURLRedirectionResponse
}

export const useAddURLRedirection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addURLRedirection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GET_REDIRECTION_LIST'] })
    }
  })
}
