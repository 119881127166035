import { CldOgImage } from 'next-cloudinary'
import NextHead from 'next/head'
import React from 'react'

export interface IHeadProps {
  ogImage?: IOGImageProps
  canonicalUrl?: string
  noIndex?: boolean
  disableCustomMeta?: boolean
  [x: string]: any
}

export interface IOGImageProps {
  titleText?: string
  imageUrl: string
  alt: string
}

export const Head = ({
  title,
  description,
  ogImage,
  canonicalUrl,
  noIndex,
  disableCustomMeta = false
}: IHeadProps) => {
  let constructedTitle = `NZ Safety Blackwoods`
  if (title) {
    constructedTitle = disableCustomMeta ? title : `${title} - NZ Safety Blackwoods`
  }

  const constructedDescription =
    description ??
    `NZ Safety Blackwoods - New Zealand's leading supplier of safety equipment, engineering supplies, uniforms and packaging.`

  /*
   * TODO:
   * - Add other meta tags
   * - Implement Next-SEO
   *
   * References:
   * https://www.npmjs.com/package/next-seo
   */

  return (
    <>
      <NextHead>
        <title>{constructedTitle}</title>
        <meta name='description' content={constructedDescription} />

        {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
        {noIndex && <meta name='robots' content='noindex,nofollow' />}

        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff'></meta>
        {/** ToDO: Remove this when UAT */}
        {process.env.NEXT_PUBLIC_INTERNAL_API_URI === 'https://nzsb.co.nz' && (
          <meta name='robots' content='noindex,nofollow' />
        )}
        {/** Facebook Meta Tags */}
        <meta property='og:title' content={constructedTitle} />
        <meta property='og:description' content={constructedDescription} />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='NZ Safety Blackwoods' />
        {/** Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={constructedTitle} />
        <meta name='twitter:description' content={constructedDescription} />
        {/** Google Search Console */}
        {/* <meta name='google-site-verification' content='3q6QV7Z7fJ9s4L1k0D7KZ3p0q0Qx5d8q7X9m7m3X7qo' /> */}
      </NextHead>
      {ogImage?.imageUrl ? (
        <CldOgImage
          width={800}
          height={500}
          crop='pad'
          src={ogImage.imageUrl}
          alt={ogImage.alt}
          overlays={[
            {
              publicId: 'General-Use/Logo_Type_3_Stamp.png',
              width: 98,
              height: 113,
              position: {
                gravity: 'north_east',
                x: 5,
                y: 10
              }
            }
          ]}
        />
      ) : (
        <CldOgImage
          width={800}
          height={600}
          crop='fit'
          src='General-Use/Logo_Type_3_Meta'
          alt='NZ Safety Blackwoods'
        />
      )}
    </>
  )
}

export default Head
