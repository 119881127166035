import { useMutation, useQueryClient } from '@tanstack/react-query'
import { httpDELETE } from 'lib/http'

export interface IDeleteURLRedirectionResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export const deleteURLRedirection = async (id: string, context?: any) => {
  const { data } = await httpDELETE({
    url: `seo/delete/redirection/${id}`,
    context
  })

  return data as IDeleteURLRedirectionResponse
}

export const useDeleteURLRedirection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteURLRedirection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GET_REDIRECTION_LIST'] })
    }
  })
}
