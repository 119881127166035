import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

interface SidePaneProps {
  className?: string
  componentId?: string
  isActive?: boolean
  focusableSidePanelList?: {
    id: number
    itemName: string
  }[]
  urlSlug?: string
}

export const SidePane: FC<SidePaneProps> = ({
  className,
  componentId,
  isActive,
  focusableSidePanelList,
  urlSlug,
  ...restProps
}: SidePaneProps) => {
  const FocusableSidePanelClasses = CN(
    `focusable-side-panel h-full w-full hidden lg:flex`,
    className
  )
  const router = useRouter()
  const [activeItem, setActiveItem] = useState<number>(1)

  const scrollToElement = (element: string) => {
    if (element) {
      const el = document.getElementById(element?.replace(/_/g, ' ')) // Convert underscores back to spaces for element IDs
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (router.query.section && focusableSidePanelList) {
      const decodedSection = decodeURIComponent(router.query.section as string).replace(/_/g, ' ') // Decode and replace underscores with spaces
      const matchedItem = focusableSidePanelList?.find(item => item?.itemName === decodedSection)

      if (matchedItem) {
        setActiveItem(matchedItem.id)
      }
    }
  }, [router.query.section, focusableSidePanelList])

  const handleClick = (item: any) => {
    setActiveItem(item?.id)
    const sectionRef = item?.itemName?.replace(/\s+/g, '_')
    if (sectionRef) {
      scrollToElement(sectionRef)
    }
  }

  return (
    <div className={FocusableSidePanelClasses} {...restProps} data-component-id={componentId}>
      <div
        className={CN(
          'focusable-side-panel-button flex w-full flex-col border-l-Gray-200 text-left h-fit gap-[8px]'
        )}
        data-component-id={`${componentId}-side-panel`}>
        {focusableSidePanelList?.map((item, id: number) => {
          const sectionRef = item?.itemName?.replace(/\s+/g, '_')

          return (
            <Link
              href={`/${urlSlug}/${router.query.slug}?section=${sectionRef}`}
              scroll={false}
              tabIndex={0}
              key={`${item?.id ?? id}-side-panel-item`}
              data-component-id={`${componentId}-side-panel-item-${id}`}
              className={CN(
                'flex py-[8px] px-[16px] cursor-pointer hover:bg-N-25 rounded text-left ',
                {
                  'text-sm text-B-500 font-500 cursor-default': activeItem === item?.id,
                  'text-sm text-N-700 font-400 text-small hover:text-Gray-600':
                    activeItem !== item?.id,
                  '!hidden pointer-events-none': !item?.itemName?.trim()
                }
              )}
              onClick={() => {
                handleClick(item)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleClick(item)
                }
              }}>
              {item?.itemName}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default SidePane
