import { useRouter } from 'next/router'
import React, { FC, ReactNode } from 'react'
import { Tabs } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

export interface ISubHeaderProps {
  activeTabId?: any
  Breadcrumb?: any
  classNameContainer?: string
  className?: string
  componentId?: string
  tabClassName?: string
  tabDetails?: { id?: any; tabTitle?: string | ReactNode; path?: string; subChildren?: any }[]
  title?: string
  titleClassName?: string
}

export const SubHeader: FC<ISubHeaderProps> = ({
  activeTabId,
  Breadcrumb,
  classNameContainer,
  className,
  componentId,
  tabClassName,
  tabDetails = [],
  title,
  titleClassName,
  ...restProps
}: ISubHeaderProps) => {
  const SubHeaderClasses = CN(
    `${componentId}-sub-header section container flex flex-col pt-[24px] gap-[24px]`,
    classNameContainer
  )

  const router = useRouter()
  const { changePasswordModal } = useModalContext()

  const changeRoute = (path?: string, onTabClick?: () => void) => {
    if (path) {
      router.push(path)
    } else if (onTabClick) {
      onTabClick()
    }
  }

  const preppedTabDetails = tabDetails?.map(
    ({ id, tabTitle, onTabClick, subChildren, path }: any) => {
      return {
        id: id,
        title: tabTitle,
        subChildren: subChildren?.map((subTab: any) => {
          if (subTab?.permissionName === 'CHANGE_PASSWORD') {
            return { ...subTab, onTabClick: () => changePasswordModal.open() }
          } else {
            return { ...subTab }
          }
        }),
        path,
        onTabClick: onTabClick
      }
    }
  )

  return (
    <div className={CN('bg-N-25', className)}>
      <section className={SubHeaderClasses} {...restProps}>
        <div className='flex flex-col gap-1'>
          {Breadcrumb}
          <h1
            data-component-id={`${componentId}-${title}`}
            className={CN(
              'font-normal text-display-h1-m lg:text-display-h1 font-display',
              titleClassName
            )}>
            {title}
          </h1>
        </div>
        <Tabs
          componentId={`${componentId}-tabs`}
          isBordered={false}
          onClick={({ path, onTabClick }: any) => {
            changeRoute(path, onTabClick)
          }}
          tabDetails={preppedTabDetails}
          activeTabId={activeTabId}
          className={CN('pb-[4px]', tabClassName)}
        />
      </section>
    </div>
  )
}

export default SubHeader
