import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IURLRedirectionRequest {
  action: number | null
  status: number[]
  searchText: string | null
  pageIndex: string
  pageSize: string
}

export interface IURLRedirection {
  id: number
  oldUrl: string
  newUrl: string
  bulkNumber: string
  redirectionListId: string
  cloudflareItemId: string
  subPathMapping: boolean
  isSecondaryValidationPassed: boolean
  entityType: number
  statusCode: number
  isManual: boolean
}

export interface IGetURLRedirectionResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: {
    representations: IURLRedirection[]
    pageIndex: number
    pageSize: number
    resultCount: number
    totalPages: number
  }
}

export const getURLRedirection = async (req: IURLRedirectionRequest, context?: any) => {
  const { data } = await httpPOST({
    url: `seo/urlRepresentation`,
    body: req,
    context
  })

  return data as IGetURLRedirectionResponse
}

export const useGetURLRedirection = (
  req: IURLRedirectionRequest,
  initialData?: IGetURLRedirectionResponse
) => {
  /* Use Query */
  return useQuery({
    queryKey: ['GET_REDIRECTION_LIST', req],
    queryFn: () => getURLRedirection(req),
    placeholderData: previousData => previousData ?? initialData
  })
}
