import React, { FC, useState } from 'react'
import { Modal, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'
import { generateRandomKey } from 'lib/utilities/generateRandomKey'

import { ForgotPassword } from 'components/forms/ForgotPassword'

export interface IForgotPasswordModalProps {
  className?: string
}

export const ForgotPasswordModal: FC<IForgotPasswordModalProps> = ({
  className,
  ...restProps
}: IForgotPasswordModalProps) => {
  const ForgotPasswordClasses = CN(`forgot-password`, className)

  const { forgotPasswordModal } = useModalContext()

  const [showBackToAction, setShowBackToAction] = useState(false)
  const [modalKey, setModalKey] = useState(generateRandomKey())

  const hideModal = () => {
    forgotPasswordModal?.close()
    setShowBackToAction(false)
  }

  const enableBackToAction = () => {
    setShowBackToAction(true)
  }

  const onBackTiClickAction = () => {
    setModalKey(generateRandomKey())
    forgotPasswordModal?.close()
    setShowBackToAction(false)
    forgotPasswordModal?.open()
  }

  return (
    <div className={ForgotPasswordClasses} key={modalKey} {...restProps}>
      <Modal
        componentId='forgot-password-modal'
        isOpen={forgotPasswordModal.isOpen}
        onClickOverlay={() => {
          hideModal()
        }}
        className='w-[444px] flex-shrink-0'>
        {showBackToAction ? (
          <ModalHeader
            componentId='forgot-password-modal'
            onClose={hideModal}
            heading={null}
            hasBorder={false}
            backButtonProps={{ title: 'Back to Forgot Password', onClick: onBackTiClickAction }}
          />
        ) : (
          <ModalHeader
            componentId='forgot-password-modal'
            onClose={hideModal}
            heading={null}
            hasBorder={false}
          />
        )}

        <ForgotPassword enableBackToAction={enableBackToAction} />
      </Modal>
    </div>
  )
}

export default ForgotPasswordModal
