import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { NextImage, OctagonWrapper } from 'components/atoms'

export interface IAboutUsPageNavigationCardProps {
  componentId?: string
  className?: string
  description?: string
  icon?: string
  imgKey?: string
  imgUrl?: string
  linkList?: Array<{
    id: string
    Header: string
  }>
  title?: string
  url?: string
}

export const AboutUsPageNavigationCard: FC<IAboutUsPageNavigationCardProps> = ({
  componentId,
  className,
  description,
  icon,
  imgKey,
  imgUrl,
  linkList,
  title,
  url,
  ...restProps
}: IAboutUsPageNavigationCardProps) => {
  const AboutUsPageNavigationCardClasses = CN(
    `about-us-card bg-white border border-N-100 hover:bg-N-50 rounded h-full`,
    className
  )

  const router = useRouter()

  const scrollToElement = (element: string) => {
    if (element) {
      const el = document.getElementById(element.replace(/_/g, ' ')) // Convert underscores back to spaces for element IDs
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div
      className={AboutUsPageNavigationCardClasses}
      {...restProps}
      data-component-id={`${componentId}-section`}>
      <div>
        <div>
          {imgUrl && (
            <div className='overflow-hidden'>
              <div className='relative h-[176px] overflow-hidden block w-full object-cover'>
                <NextImage
                  nextImgStyle={{ objectFit: 'cover' }}
                  data-component-id={`${componentId}-image-section-img`}
                  imgKey={imgKey ?? ''}
                  imageUrl={imgUrl}
                  system='CMS'
                  useWithDimensions={false}
                  alt={''}
                />
              </div>
            </div>
          )}

          {/** About Us Page Navigation Card Icon Section */}
          {icon && (
            <div className='flex'>
              <span>
                <OctagonWrapper
                  componentId={`${componentId}-icon-section`}
                  icon={icon ?? ''}
                  className='relative flex items-center justify-center ml-[20px] mt-[-32px]'
                />
              </span>
            </div>
          )}
        </div>

        {/** About Us Page Navigation Card Content Section */}
        <div data-component-id={`${componentId}-body-content`} className='flex flex-col p-[24px]'>
          {/** About Us Page Navigation Card Title And Description Section */}
          <div
            data-component-id={`${componentId}-body-content-title-and-description`}
            className='flex flex-col gap-[20px]'>
            {/** About Us Page Navigation Card Title Section */}
            {title && (
              <div
                data-component-id={`${componentId}-body-content-title-section`}
                className='text-h3 font-semibold text-N-700'>
                <Link
                  data-component-id={`${componentId}-body-content-title-text`}
                  href={`/about-us/${url}`}>
                  {title}
                </Link>
              </div>
            )}

            {/** About Us Page Navigation Card Description Section */}
            {description && (
              <div
                data-component-id={`${componentId}-body-content-description`}
                className='text-base font-400 text-N-700 break-words max-w-full'>
                {description}
              </div>
            )}
          </div>

          {/** About Us Page Navigation Card Link List Section */}
          <div data-component-id={`${componentId}-body-content-link-list`} className='mt-[40px]'>
            {linkList?.map((link, index) => (
              <Link
                key={`${link?.Header ?? ''}-${index}-link`}
                onClick={e => {
                  e.preventDefault() // Prevent default immediate navigation

                  // Define a handler to scroll after navigation
                  const handleRouteChange = () => {
                    scrollToElement(link?.Header.replace(/\s+/g, '_'))
                    router.events.off('routeChangeComplete', handleRouteChange) // Cleanup event
                  }

                  router.events.on('routeChangeComplete', handleRouteChange) // Attach event

                  router.push(
                    {
                      pathname: `/about-us/${url}`,
                      query: { section: link?.Header.replace(/\s+/g, '_') }
                    },
                    undefined,
                    { scroll: false }
                  )
                }}
                href={{
                  pathname: `/about-us/${url}`,
                  query: { section: link?.Header.replace(/\s+/g, '_') } // Convert spaces to underscores
                }}>
                <Button
                  data-component-id={`${componentId}-body-content-link-${index}`}
                  key={`${link?.Header ?? ''}-${index}-button`}
                  className='mb-4 text-left'
                  size='md'
                  appearance='link-gray'>
                  {link?.Header}
                </Button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsPageNavigationCard
