import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Button, CheckBoxCard } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { changeRouter } from 'lib/utilities'

import { DateInputWithPicker } from 'components/atoms'

export interface StatementFilterSectionProps {
  className?: string
  componentId?: string
  onClickReset?: () => void
}

export const StatementFilterSection: FC<StatementFilterSectionProps> = ({
  className,
  componentId,
  onClickReset
}: StatementFilterSectionProps) => {
  const StatementFilterSectionClasses = CN(
    'w-full h-fit flex flex-col p-6 bg-white rounded border border-[#D9D9D5] gap-y-6',
    className
  )
  const router = useRouter()
  const params = useSearchParams()

  const DATE_PICKER_PLACE_HOLDER = ''
  const UNPAID = 'unpaid'
  const FROM = 'from'
  const TO = 'to'
  const PAGE = 'page'
  const PAGE_SIZE = 'pageSize'

  const unpaidParam = params.get(UNPAID)

  const [filterDateRange, setFilterDateRange] = useState<any>({
    startDate: null,
    endDate: null,
    key: null
  })
  const [checked, setChecked] = useState(unpaidParam !== 'false')

  const currentDate = new Date()

  const [filterButtonText, setFilterButtonText] = useState(DATE_PICKER_PLACE_HOLDER)

  const onResetClick = () => {
    onClickReset?.()
    setFilterDateRange({
      startDate: null,
      endDate: null,
      key: 'selection'
    })
    setFilterButtonText(DATE_PICKER_PLACE_HOLDER)
    setChecked(true)

    changeRouter(router, undefined, undefined, [FROM, TO, PAGE, PAGE_SIZE, UNPAID])
  }

  useEffect(() => {
    if (!unpaidParam) {
      setChecked(true)
    }
  }, [params])

  return (
    <div className='flex flex-col gap-6'>
      <div className={StatementFilterSectionClasses}>
        <div className='flex w-full justify-between items-center gap-x-3'>
          <p className='font-700 text-base text-N-700'>Filters</p>
          <Button
            componentId={`${componentId}-filter-section-resetAllFilters`}
            iconBefore='nzsbi-cycle text-[24px]'
            size='md'
            appearance='link-gray'
            onClick={() => {
              onResetClick()
            }}>
            Reset All Filters
          </Button>
        </div>

        <div className={CN('grid grid-cols-1 md:!grid-cols-2 gap-4 lg:!grid-cols-4')}>
          <DateInputWithPicker
            label='Date'
            componentId='invoices-and-credits-date-range-picker'
            filterButtonText={filterButtonText}
            setFilterButtonText={setFilterButtonText}
            filterDateRange={filterDateRange}
            setFilterDateRange={setFilterDateRange}
            minDate={
              new Date(currentDate.getFullYear() - 7, currentDate.getMonth(), currentDate.getDate())
            }
            maxDate={new Date()}
          />

          <div className='w-full flex flex-col'>
            <div className='pb-1 font-500 text-base'>Status</div>
            <CheckBoxCard
              componentId={`${componentId}-${UNPAID}`}
              checked={checked}
              label='Unpaid'
              id={UNPAID}
              onChange={e => {
                setChecked(e.target.checked)
                if (e.target.checked) {
                  changeRouter(router, UNPAID, 'true', [PAGE])
                } else {
                  changeRouter(router, UNPAID, 'false', [PAGE])
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatementFilterSection
