import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IStatementsListReq {
  dateRange?: {
    startDate: string
    endDate: string
  }
  isUnpaid: boolean
  pageIndex: number
  pageSize: number
  sortFilter?: {
    field: string
    type: string
  }
}

export interface IStatements {
  attachmentName: string
  attachmentPath: string
  customerEmail: string
  customerId: string
  date: string
  isPdfAvailable: boolean
  name: string
  status: number
}

export interface IStatementListData {
  statementList: Array<IStatements>
  pageIndex: number
  pageSize: number
  resultCount: number
  totalPages: number
}

export interface IStatementListRes {
  data: IStatementListData
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

/**
 * Fetch order history
 * @param req Request object for fetching order history
 * @param context This is required for SSR only, otherwise the token is not attached
 * @returns IStatementListRes
 */
export const fetchStatements = async (req: IStatementsListReq, context?: any) => {
  const { data } = await httpPOST({
    url: '/statement/get_statement_list',
    body: req,
    context
  })

  return data?.data as IStatementListRes
}

export const useGetStatements = (req: IStatementsListReq, initialData?: any) => {
  /* Use Query */
  return useQuery({
    queryKey: ['GET_STATEMENETS_LIST', req],
    queryFn: () => fetchStatements(req),
    initialData: initialData
  })
}

export default useGetStatements
