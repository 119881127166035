import React, { FC, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { IAllCategory, useGetAllCategoriesWithoutMinor } from 'lib/actions'

import { MobileMenuItem } from '../MobileMenuItem'
import { MobileMenuProductTypeMenu } from '../MobileMenuProductTypeMenu'
import { MobileMenuSubHeader } from '../MobileMenuSubHeader'

export interface IMobileMenuItemsDetails {
  level: number
  selectedItem: string
}

export interface IMobileMenuProductPillarMenuProps {
  className?: string
  componentId?: string
  mobileMenuItemsDetails?: IMobileMenuItemsDetails
  onBackClick?: () => void
}

export const MobileMenuProductPillarMenu: FC<IMobileMenuProductPillarMenuProps> = ({
  className,
  componentId,
  mobileMenuItemsDetails,
  onBackClick,
  ...restProps
}: IMobileMenuProductPillarMenuProps) => {
  const { data: categoryData, isLoading: categoryIsLoading } = useGetAllCategoriesWithoutMinor()

  const [isPillarMenuOpen, setIsPillarMenuOpen] = useState(true)
  const [pillarTypesObj, setPillarTypesObj] = useState<IAllCategory | null>(null)

  return (
    <div data-component-id={`${componentId}-section`} {...restProps} className='flex flex-col'>
      {isPillarMenuOpen && (
        <MobileMenuSubHeader
          subHeaderTitle={mobileMenuItemsDetails?.selectedItem}
          onBackClick={onBackClick}
        />
      )}

      <div className='flex flex-col'>
        {categoryIsLoading && (
          <Skeleton
            data-component-id={`${componentId}-section-loader`}
            count={5}
            width={'100%'}
            height={36}
            containerClassName='flex flex-col mt-7'
          />
        )}
        {isPillarMenuOpen && (
          <ul className='flex flex-col gap-y-3 mt-7 h-[796px] overflow-y-auto list-none'>
            {categoryData?.data?.categories?.map((item: IAllCategory, index: number) => {
              return (
                <MobileMenuItem
                  key={item.id}
                  componentId={`${componentId}-pillar-menu-item-${index}`}
                  iconBefore={item.icon}
                  isIconAfter={true}
                  iconBeforeClassName='text-[20px] text-N-500'
                  menuItemName={item.name}
                  menuItemClassName='w-full font-400 text-sm text-N-700 text-left'
                  iconAfterClassName='text-[20px] text-N-500'
                  className='!px-3 !py-2'
                  urlSlug={item?.urlSlug}
                  onMainMenuItemClick={() => {
                    setIsPillarMenuOpen(false)
                    setPillarTypesObj(item)
                  }}
                />
              )
            })}
          </ul>
        )}
      </div>

      {/* Third level menu */}
      {!isPillarMenuOpen && (
        <MobileMenuProductTypeMenu
          componentId={`${componentId}-selected-pillar-menu`}
          data={pillarTypesObj}
          setIsPillarMenuOpen={setIsPillarMenuOpen}
        />
      )}
    </div>
  )
}

export default MobileMenuProductPillarMenu
