export const convertDate = (dateString: string) => {
  /**
   * @param dateString - [string] date in string format
   * @returns - [string] date in dd/mm/yyyy format
   */

  if (dateString === null || dateString === undefined) {
    return ''
  }

  const d = new Date(dateString)

  if (isNaN(d.getTime())) {
    return ''
  }

  const mm = (d.getMonth() + 1).toString().padStart(2, '0')
  const dd = d.getDate().toString().padStart(2, '0')
  const yy = d.getFullYear()

  return `${dd}/${mm}/${yy}`
}

export const formatToDMYConversion = (
  dateString: string,
  monthFormat: 'short' | '2-digit' | 'numeric' | 'long' | 'narrow' = 'short'
) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: monthFormat,
    year: 'numeric'
  })
}

export const convertToLocal = (utcDate?: string) => {
  if (!utcDate) return ''
  const date = new Date(`${utcDate}Z`)
  return date
}

export const formatToDMYHMConversion = (
  dateString?: string,
  monthFormat: 'short' | 'long' = 'short'
) => {
  if (!dateString) return ''

  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleString('en-GB', { month: monthFormat })
  const year = date.getFullYear()
  const hours = date.getHours() % 12 || 12
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const amPm = date.getHours() < 12 ? 'AM' : 'PM'

  return `${day} ${month} ${year} ${hours}:${minutes} ${amPm}`
}

export default convertDate
