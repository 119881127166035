import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { debounce, Grid } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import {
  IAllCategory,
  useGetAllCategoriesWithoutMinor,
  useGetCMSCategoryPopularBrands
} from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'

import {
  AllCategorySideBar,
  MegaMenuMostPopularBrandSection,
  MegaMenuSubCategorySection
} from 'components/molecules'

export interface IMegaMenuModalProps {
  className?: string
}

export const MegaMenuModal: FC<IMegaMenuModalProps> = ({
  className,
  ...restProps
}: IMegaMenuModalProps) => {
  const [hoverItem, setHoverItem] = useState<IAllCategory>()

  const router = useRouter()
  const { megaMenuModal } = useModalContext()
  // get all category data without minors
  const { data: categoryData, isLoading: categoryIsLoading } = useGetAllCategoriesWithoutMinor()

  useEffect(() => {
    // set first category pillar as hovered item
    if (!categoryIsLoading && categoryData?.data?.categories)
      setHoverItem(categoryData?.data?.categories[0])
  }, [categoryData])

  const {
    data: brandsData,
    isLoading: brandsIsLoading,
    refetch: brandRefetch
  } = useGetCMSCategoryPopularBrands(hoverItem?.urlSlug || '')

  const onCategoryClick = (category: IAllCategory) => {
    megaMenuModal.close()
    router.push(`/category/${category?.urlSlug}`)
  }

  const onCategoryHover = useCallback(
    debounce((category: IAllCategory) => {
      if (hoverItem !== category) {
        setHoverItem(category)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (hoverItem) brandRefetch()
  }, [hoverItem])

  const ProductsDropdownBodyClasses = CN(
    `products-dropdown bg-white h-full flex flex-col relative justify-between !py-8 lg:!px-8 md:!pl-3 md:!pr-6 md:gap-[32px]`
  )

  return (
    <div className={ProductsDropdownBodyClasses} {...restProps}>
      <Grid className='!grid-cols-[12fr] md:!grid-cols-[3fr_9fr] h-full' minHeight='fit-content'>
        {/* mega menu sidebar */}
        <AllCategorySideBar
          componentId='mega-menu-all-category-sidebar'
          className='mr-3'
          isLoading={categoryIsLoading}
          items={categoryData?.data?.categories}
          onClickItem={onCategoryClick}
          onHoverItem={onCategoryHover}
          header={{
            title: 'All categories',
            actionButton: {
              name: 'See All',
              onClick: () => {
                megaMenuModal.close()
                router.push(`/category/all-categories`)
              }
            }
          }}
        />
        <div className='flex flex-col w-full h-full gap-5'>
          {/* mega menu category types, majors  */}
          <MegaMenuSubCategorySection
            isLoading={categoryIsLoading}
            componentId='mega-menu-sub-category-section'
            onClose={megaMenuModal.close}
            categoryItems={hoverItem?.childCategories}
            headerTitle={hoverItem?.name}
            iconName={hoverItem?.icon}
            categorySlug={hoverItem?.urlSlug}
          />
          {/* mega menu category pillar popular brand section */}
          <div className='lg:w-[876px] md:w-[426px]'>
            <MegaMenuMostPopularBrandSection
              componentId='popular-brand-mega-menu'
              hoverItemName={hoverItem?.name}
              brandsData={brandsData?.Brands?.data}
              isLoading={brandsIsLoading}
              mostPopularBrandItems={brandsData?.Brands?.data || []}
            />
          </div>
        </div>
      </Grid>
    </div>
  )
}

export default MegaMenuModal
