import React, { FC, useMemo } from 'react'
import { Button, Lozenge, LozengeProps, priceFormat, Thumbnail } from '@nzsb/shopnx-ui'
import { orderStatusData } from 'lib/static/orderHistoryStaticData'
import { convertDate } from 'lib/utilities'

import { NextImage } from 'components/atoms'

import { DataTable } from '../DataTable'

export interface Invoice {
  invoiceId: string
  trackingId: string
  trackingUrl: string
}

export interface Product {
  allocated: number
  exceptedDeliveryDate: string
  fullyDispatched: number
  imageUrl: string
  invoices: Invoice[]
  isCatalogue: boolean
  linePrice: number
  name: string
  notDispatched: number
  onPick: number
  ordered: number
  outstanding: number
  quantity: number
  quantityDispatched: number
  sku: string
  status: number
  subTotal: number
  supplied: number
  uom: string
  variantId: number
  webId: number
}

export interface IOrderDetailsDataTableProps {
  componentId?: string
  orderLineData: Product[]
}

export const OrderDetailsDataTable: FC<IOrderDetailsDataTableProps> = ({
  componentId,
  orderLineData
}: IOrderDetailsDataTableProps) => {
  const getCellContent = (row: any, rowHeader: string) => {
    switch (rowHeader) {
      case 'product':
        return (
          <div className='flex gap-x-2 py-1 h-full'>
            <div className='relative w-[62px] h-[64px]'>
              {row?.original?.imageUrl ? (
                <NextImage
                  data-component-id={`${componentId}-${row?.original?.sku}-product-image`}
                  imgKey={`${row?.original?.sku}-${row?.original?.sku}`}
                  imageUrl={row?.original?.imageUrl}
                  useWithDimensions={false}
                  alt={row?.original?.sku}
                />
              ) : (
                <Thumbnail isZoomable={false} componentId={componentId} />
              )}
            </div>
            <div className='flex flex-col gap-y-1 justify-start w-[352px]'>
              <div className='text-sm font-500 text-N-700'>{row?.original?.name}</div>
              <div className='text-xs font-500 text-N-500'>{row?.original?.sku}</div>
            </div>
          </div>
        )
      case 'status':
        return (
          <span className='flex flex-col py-1 h-full'>
            <Lozenge
              appearance='subtle'
              variant={
                orderStatusData[row?.original?.status as keyof typeof orderStatusData]
                  ?.variant as LozengeProps['variant']
              }>
              {orderStatusData[row?.original?.status as keyof typeof orderStatusData]?.label}
            </Lozenge>
            {row?.original?.status === 1 && (
              <span className='pt-1 text-xs text-N-500 font-400'>
                Expected {convertDate(row?.original?.exceptedDeliveryDate)}
              </span>
            )}
          </span>
        )
      case 'ordered':
        return (
          <div className='flex text-N-600 font-500 py-1 h-full'>
            {row?.original?.supplied} / {row?.original?.ordered}
          </div>
        )
      case 'invoiceId':
        return (
          <span className='flex flex-col text-N-600 font-500 py-1 h-full'>
            {row?.original?.invoices?.map((invoice: any) => (
              <span key={invoice?.invoiceId}>{invoice?.invoiceId}</span>
            ))}
          </span>
        )
      case 'trackingUrl':
        return (
          <div className='flex flex-col text-N-600 font-500 py-1 h-full'>
            {row?.original?.invoices?.length === 0 && <span>N/A</span>}
            {row?.original?.invoices?.map((invoice: any) => {
              if (invoice?.trackingUrl) {
                return (
                  <Button
                    key={invoice.invoiceId}
                    appearance='link'
                    className='w-fit px-0'
                    isRingOffset={false}
                    onClick={() => {
                      window.open(invoice.trackingUrl, '_blank')
                    }}>
                    {invoice?.trackingId}
                  </Button>
                )
              } else {
                return <span key={invoice?.invoiceId}>N/A</span>
              }
            })}
          </div>
        )
      case 'linePrice':
        return (
          <span className='flex text-N-600 font-500 py-1 h-full'>
            ${priceFormat(row?.original?.linePrice)}
          </span>
        )
      case 'subTotal':
        return (
          <span className='flex text-N-600 font-500 py-1 h-full'>
            ${priceFormat(row?.original?.subTotal)}
          </span>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col'>
            <div className='flex gap-x-2'>
              <div className='relative w-[62px] h-[64px]'>
                {row?.original?.imageUrl ? (
                  <NextImage
                    data-component-id={`${componentId}-${row?.original?.sku}-product-image`}
                    imgKey={`${row?.original?.sku}-${row?.original?.sku}`}
                    imageUrl={row?.original?.imageUrl}
                    useWithDimensions={false}
                    alt={row?.original?.sku}
                  />
                ) : (
                  <Thumbnail isZoomable={false} componentId={componentId} />
                )}
              </div>
              <div className='flex flex-col gap-y-1 justify-center'>
                <div className='text-sm font-500 text-N-700'>{row?.original?.name}</div>
                <div className='text-xs font-500 text-N-500'>{row?.original?.sku}</div>
              </div>
            </div>

            <div className='flex flex-col pt-4'>
              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Delivery Status:</span>
                <span className='flex flex-col'>
                  <Lozenge
                    appearance='subtle'
                    variant={
                      orderStatusData[row?.original?.status as keyof typeof orderStatusData]
                        ?.variant as LozengeProps['variant']
                    }>
                    {orderStatusData[row?.original?.status as keyof typeof orderStatusData]?.label}
                  </Lozenge>
                  {row?.original?.status === 1 && (
                    <span className='pt-1 text-xs text-N-500 font-400'>
                      Expected {convertDate(row?.original?.exceptedDeliveryDate)}
                    </span>
                  )}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Supplied / Ordered:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {row?.original?.supplied} / {row?.original?.ordered}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Invoice ID:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {row?.original?.invoices?.map((invoice: any) => (
                    <span key={invoice?.invoiceId}>{invoice?.invoiceId}</span>
                  ))}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Tracking ID:</span>
                <div className='flex flex-col text-N-600 font-500'>
                  {row?.original?.invoices?.map((invoice: any) => (
                    <Button
                      key={invoice.invoiceId}
                      appearance='link'
                      className='w-fit px-0'
                      isRingOffset={false}
                      onClick={() => {
                        window.open(invoice.trackingUrl, '_blank')
                      }}>
                      {invoice?.trackingId}
                    </Button>
                  ))}
                </div>
              </div>
              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Item Price:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  ${priceFormat(row?.original?.linePrice)}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Sub-total:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  ${priceFormat(row?.original?.subTotal)}
                </span>
              </div>
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50`

  const DESKTOP_COLUMNS = useMemo(() => {
    return [
      {
        Header: () => 'Product',
        accessor: 'product',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[454px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'product')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Delivery Status',
        accessor: 'status',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[210px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'status')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Supplied / Ordered',
        accessor: 'ordered',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[140px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'ordered')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[119px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'invoiceId')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Tracking ID',
        accessor: 'trackingUrl',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[127px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'trackingUrl')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Item Price',
        accessor: 'linePrice',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[112px] !max-w-[113px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'linePrice')
        },
        cellClassName: 'h-[88px]'
      },
      {
        Header: 'Sub-Total',
        accessor: 'subTotal',
        cellHeaderClassName: `${cellHeaderClassName} min-w-[116px] !max-w-[117px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'subTotal')
        },
        cellClassName: 'h-[88px]'
      }
    ]
  }, [])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [])

  const tableProps = {
    enablePagination: false,
    data: orderLineData || []
  }

  return (
    <div
      className='flex w-full h-fit border-x border-t border-N-50 rounded'
      data-component-id={`order-details-order-line-table-${componentId}`}>
      {/* Desktop View */}
      <DataTable mainDivClassName='hidden lg:!block' columns={DESKTOP_COLUMNS} {...tableProps} />

      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        {...tableProps}
      />
    </div>
  )
}

export default OrderDetailsDataTable
