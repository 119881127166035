import React, { FC, useState } from 'react'
import { PasswordStrengthChecker, StrengthBar, TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
export interface IPasswordInputProps {
  [x: string]: any
  className?: string
  id?: any
  inputWrapperClassName?: string
  isStrengthBarEnable?: boolean
  label?: string
  onChange?: any
  componentId?: string
}
export const PasswordInput: FC<IPasswordInputProps> = ({
  className,
  id,
  inputWrapperClassName,
  isStrengthBarEnable = false,
  label,
  onChange,
  componentId,
  ...restProps
}: IPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showStrengthBar, setShowStrengthBar] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const PasswordInputClasses = CN(`password-input relative`, className)
  return (
    <div className={PasswordInputClasses}>
      <TextField
        componentId={componentId}
        id={id}
        label={label}
        style={{ paddingRight: '40px' }}
        type={showPassword ? 'text' : 'password'}
        wrapperClassName={CN(
          'w-full',
          {
            'pb-1': showStrengthBar
          },
          inputWrapperClassName
        )}
        iconAfter={!showPassword ? 'ri-eye-line !text-base' : 'ri-eye-off-line !text-base'}
        onClickIconAfter={() => setShowPassword(!showPassword)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e, PasswordStrengthChecker(e?.target?.value, true))
          }
          setPasswordStrength(PasswordStrengthChecker(e?.target?.value, true))
          if (e?.target?.value === '') {
            setShowStrengthBar(false)
          } else {
            setShowStrengthBar(true)
          }
        }}
        {...restProps}
      />

      {isStrengthBarEnable && showStrengthBar && <StrengthBar progressCount={passwordStrength} />}
    </div>
  )
}
export default PasswordInput
