import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { Alert, Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { PermissionMenuProps } from 'types/myAccount'
import { useGetBasicAccountInfo } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import useWindowDimensions from 'lib/hooks/useWindowDimensions'
import { ADMIN_MENU, FINANCE_MENU, ORDERING_MENU, SETTINGS_MENU } from 'lib/static/myAccount'

export interface IMyAccountDropdownBodyProps {
  className?: string
  isLinkedCRAccount?: boolean
  handleCreateCRAccount?: any
  logout?: any
}

const PermissionList = ({
  handleMenuItemClick,
  isLinkedCRAccount,
  title,
  permissionsList
}: {
  handleMenuItemClick: (href?: string, onClick?: () => void) => void
  isLinkedCRAccount?: boolean
  title: string
  permissionsList: PermissionMenuProps[]
}) => {
  return (
    <div
      className={CN('flex flex-col md:border-none pt-[20px] md:!pt-[0px] w-full', {
        'border-t border-t-N-100': title !== 'Ordering'
      })}>
      <span
        className={`myAccount-dropDownBody-${title} text-xs font-700 uppercase text-B-500 ml-[16px] pb-[8px]`}>
        {title}
      </span>
      <div className='flex flex-col items-start gap-[4px] md:pr-[32px] px-[4px]'>
        {permissionsList.map(
          ({
            href,
            icon,
            id,
            isDisabled,
            isLinkedCRAccountMenu,
            label,
            onClick
          }: PermissionMenuProps) => {
            /** ordering menu list without CR Account  */
            if (isLinkedCRAccountMenu && !isLinkedCRAccount)
              return (
                <Button
                  componentId={`my-account-dropdown-${label?.toLowerCase()?.replaceAll(' ', '-')}`}
                  key={id}
                  className={CN('text-left w-full pl-[12px]', {
                    '!bg-white': isDisabled
                  })}
                  innerDivClass='!justify-start !m-[0px]'
                  disabled={isDisabled}
                  appearance='tertiary-gray'
                  iconBefore={icon}
                  isRingOffset={false}
                  onClick={() => handleMenuItemClick(href, onClick)}>
                  <span className='capitalize '>{label}</span>
                </Button>
              )

            /** Ordering menu list with CR Account */
            if (isLinkedCRAccount)
              return (
                <Button
                  key={id}
                  componentId={`my-account-dropdown-${label?.toLowerCase()?.replaceAll(' ', '-')}`}
                  className={CN('text-left w-full pl-[12px]', {
                    '!bg-white': isDisabled
                  })}
                  innerDivClass='!justify-start !m-[0px]'
                  disabled={isDisabled}
                  appearance='tertiary-gray'
                  isRingOffset={false}
                  iconBefore={icon}
                  onClick={() => handleMenuItemClick(href, onClick)}>
                  <span className='capitalize '>{label}</span>
                </Button>
              )
          }
        )}
      </div>
    </div>
  )
}

export const MyAccountDropdownBody: FC<IMyAccountDropdownBodyProps> = ({
  className,
  isLinkedCRAccount,
  handleCreateCRAccount,
  logout,
  ...restProps
}: IMyAccountDropdownBodyProps) => {
  const MyAccountDropdownBodyClasses = CN(
    `my-account-dropdown bg-white w-full flex flex-col relative h-full justify-between overflow-hidden md:gap-[32px]`,
    className
  )

  /** app/Modal contexts for my account drop down */
  const { changePasswordModal, linkToCreditAccountModal, myAccountModal } = useModalContext()
  const { width } = useWindowDimensions()
  const router = useRouter()

  /** basic My account API call  */
  const { data: basicAccountInfo } = useGetBasicAccountInfo()

  const { session } = useAppContext()

  /** Compare exit permission list with API (user level) list */
  const checkPermissionsExist = (
    permissionList: PermissionMenuProps[] /** Permission list from The API */,
    menu: PermissionMenuProps[] /** Menu List from Statics */
  ) => {
    return menu?.map((menuItem: any) => {
      if (permissionList?.find((permission: any) => permission === menuItem.permissionName)) {
        const preppedMenuItem = { ...menuItem, isDisabled: false }

        if (menuItem?.permissionName === 'CHANGE_PASSWORD') {
          return { ...preppedMenuItem, href: undefined, onClick: () => changePasswordModal.open() }
        }

        return preppedMenuItem
      } else {
        return { ...menuItem, isDisabled: true }
      }
    })
  }

  const [showWhatIsCreditAccountAlert, setShowWhatIsCreditAccountAlert] = useState(false)

  const ordering = checkPermissionsExist(basicAccountInfo?.permissions, ORDERING_MENU)
  const finance = checkPermissionsExist(basicAccountInfo?.permissions, FINANCE_MENU)
  const settings = checkPermissionsExist(basicAccountInfo?.permissions, SETTINGS_MENU)
  const admin = checkPermissionsExist(basicAccountInfo?.permissions, ADMIN_MENU)

  /** Menu routes handle */
  const handleMenuRoutes = (href: string) => {
    if (href === '/request-item') {
      router.push('/coming-soon')
    } else {
      router.push(href)
    }
    myAccountModal?.close()
  }

  const handleMenuItemClick = (href?: string, onClick?: () => void) => {
    if (href) {
      handleMenuRoutes(href)
    } else if (onClick) {
      onClick()
    }

    myAccountModal?.close()
  }

  return (
    <div className={MyAccountDropdownBodyClasses} {...restProps}>
      <div className='flex flex-col gap-[4px] w-full border-b border-b-N-100 md:border-none pb-[20px] md:pb-[0px]'>
        <span className='my-account-dropdown-fullName text-h3 text-B-500 font-700 lg:font-600 uppercase'>
          {basicAccountInfo?.fullName}
        </span>
        <span className='text-base my-account-dropdown-email text-N-500 font-500'>
          {basicAccountInfo?.emailAddress}
        </span>
        {isLinkedCRAccount && (
          <>
            <span className='text-base my-account-dropdown-accountCode text-N-500 font-500'>
              Account Number: {basicAccountInfo?.accountCode}
            </span>
            <span className='text-sm my-account-dropdown-businessName text-N-500 font-500'>
              {basicAccountInfo?.businessName}
            </span>
          </>
        )}
        <Button
          componentId='my-account-dropdown-view-my-account'
          isRingOffset={false}
          onClick={() => {
            myAccountModal.close()
            router.push('/account')
          }}
          className='w-fit text-left md:w-[170px] mt-[4px]'
          appearance={width >= 744 ? 'secondary-orange' : 'link'}>
          <span className='text-sm text-left'>View My Account</span>
        </Button>
      </div>
      <div
        className={CN('flex flex-1 flex-col md:flex-row gap-[12px] md:gap-[32px] overflow-auto')}>
        <div
          className={CN(
            'border-t  md:border-r border-r-N-200 gap-[20px] flex flex-col !first:border-b-none',
            {
              'w-full md:max-w-[227px]': !isLinkedCRAccount
            }
          )}>
          <PermissionList
            handleMenuItemClick={handleMenuItemClick}
            isLinkedCRAccount={isLinkedCRAccount}
            title='Ordering'
            permissionsList={ordering}
          />
          {!isLinkedCRAccount && (
            <PermissionList
              handleMenuItemClick={handleMenuItemClick}
              isLinkedCRAccount={isLinkedCRAccount}
              title='Account settings'
              permissionsList={settings}
            />
          )}
        </div>
        <div className={CN('flex flex-1')}>
          {/** handle CR Account  */}
          {!isLinkedCRAccount && (
            <div className='flex flex-col gap-[14px] w-full bg-N-25 md:!bg-white py-[24px] md:py-[0] h-fit px-[20px] md:!px-[0]'>
              <div className='flex flex-col w-full'>
                <span className='text-sm text-N-700'>Already have a credit account?</span>
                <Button
                  componentId='linkCreditAccountBtn'
                  isRingOffset={false}
                  appearance='primary-orange'
                  className='w-full uppercase'
                  onClick={() => {
                    linkToCreditAccountModal.open()
                    myAccountModal.close()
                  }}>
                  Link Credit Account
                </Button>
              </div>
              <div className='w-full'>
                <span className='text-sm text-N-700'>Sign up for a credit account</span>
                <Button
                  componentId='createCreditAccountBtn'
                  isRingOffset={false}
                  appearance='secondary-orange'
                  className='w-full uppercase'
                  onClick={handleCreateCRAccount}>
                  Create Credit Account
                </Button>
              </div>
              <Button
                componentId='whatIsCreditAccountBtn'
                className='w-fit'
                appearance='link-gray'
                onClick={() => {
                  setShowWhatIsCreditAccountAlert(!showWhatIsCreditAccountAlert)
                }}>
                What is a credit account?
              </Button>
              <AnimatePresence>
                {showWhatIsCreditAccountAlert && (
                  <motion.div
                    className='w-full'
                    initial={{ height: 0 }}
                    animate={{
                      height: 'auto'
                    }}
                    exit={{ height: 0, opacity: -2 }}>
                    <Alert
                      className='alert'
                      alertHeader='Apply for a credit account if you would like to pay for goods or services on invoice'
                      status='Info'
                      variant='inline'
                      closeToast={() => {
                        setShowWhatIsCreditAccountAlert(false)
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
          {/** menu list with CR Account */}
          {isLinkedCRAccount && (
            <div className='flex flex-col gap-[12px] justify-between w-full'>
              <PermissionList
                handleMenuItemClick={handleMenuItemClick}
                isLinkedCRAccount={isLinkedCRAccount}
                title='Invoicing & Finance'
                permissionsList={finance}
              />
              <PermissionList
                handleMenuItemClick={handleMenuItemClick}
                isLinkedCRAccount={isLinkedCRAccount}
                title={'Account Settings'}
                permissionsList={settings}
              />
              {basicAccountInfo?.permissions?.includes('SEO_ADMIN_PAGES') && (
                <PermissionList
                  handleMenuItemClick={handleMenuItemClick}
                  isLinkedCRAccount={isLinkedCRAccount}
                  title={'Admin'}
                  permissionsList={admin}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/** Logout Button */}
      {!(session?.isPunchOut && session?.isIframe) && (
        <div className='hidden md:!block w-full pb-[20px] md:pb-[0]'>
          <Button
            componentId='sign-out button'
            isRingOffset={false}
            className='w-full'
            appearance='secondary-orange'
            onClick={() => logout()}>
            <span className='uppercase text-R-500'>Sign Out</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default MyAccountDropdownBody
