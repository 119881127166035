import React, { FC } from 'react'
import { PickUpDetails } from 'lib/actions'

import { AddressProps } from '../AddressCard'
import { OrderHistoryAddressCard } from '../OrderHistoryAddressCard'

export interface OrderHistoryAddressSectionProps {
  invoiceAddress?: AddressProps
  pickupDetails?: PickUpDetails
  shippingAddress?: AddressProps
}

export const OrderHistoryAddressSection: FC<OrderHistoryAddressSectionProps> = ({
  invoiceAddress,
  pickupDetails,
  shippingAddress
}: OrderHistoryAddressSectionProps) => {
  const getFullName = (firstName: string, lastName: string) => {
    if (!firstName && !lastName) return ''
    if (!firstName) return lastName
    if (!lastName) return firstName
    return `${firstName} ${lastName}`
  }

  return (
    <>
      {(shippingAddress || invoiceAddress || pickupDetails) && (
        <div className='flex flex-col gap-5 md:!grid md:!grid-cols-2 md:gap-8'>
          {invoiceAddress && (
            <OrderHistoryAddressCard
              header='Invoice Address'
              componentId='order-history-invoice-address'
              companyName={
                invoiceAddress?.businessName ||
                invoiceAddress?.attentionTo ||
                getFullName(invoiceAddress?.firstName, invoiceAddress?.lastName)
              }
              name={
                invoiceAddress?.attentionTo ||
                getFullName(invoiceAddress?.firstName, invoiceAddress?.lastName)
              }
              streetAddress={invoiceAddress?.streetAddress}
              suburb={invoiceAddress?.suburb}
              city={invoiceAddress?.city}
              postCode={invoiceAddress?.postCode}
            />
          )}
          {shippingAddress && (
            <OrderHistoryAddressCard
              header='Shipping Address'
              componentId='order-history-shipping-address'
              companyName={
                shippingAddress?.businessName ||
                shippingAddress?.attentionTo ||
                getFullName(shippingAddress?.firstName, shippingAddress?.lastName)
              }
              name={
                shippingAddress?.attentionTo ||
                getFullName(shippingAddress?.firstName, shippingAddress?.lastName)
              }
              streetAddress={shippingAddress?.streetAddress}
              suburb={shippingAddress?.suburb}
              city={shippingAddress?.city}
              postCode={shippingAddress?.postCode}
            />
          )}
          {pickupDetails && (
            <OrderHistoryAddressCard
              header='Pick Up'
              componentId='order-history-pickup-details'
              companyName={pickupDetails?.branchName}
              collectPersonDetails={{
                firstName: pickupDetails?.firstName,
                lastName: pickupDetails?.lastName,
                emailAddress: pickupDetails?.emailAddress,
                contactNumber: pickupDetails?.contactNumber
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default OrderHistoryAddressSection
