import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'

export interface MobileMenuItemProps {
  className?: string
  componentId?: string
  iconAfterClassName?: string
  iconBefore?: string
  iconBeforeClassName?: string
  isIconAfter?: boolean
  menuItemClassName?: string
  menuItemName?: string
  onMainMenuItemClick?: () => void
  urlSlug?: string
}

export const MobileMenuItem: FC<MobileMenuItemProps> = ({
  className,
  componentId,
  iconAfterClassName,
  iconBefore,
  iconBeforeClassName,
  isIconAfter,
  menuItemClassName,
  menuItemName,
  onMainMenuItemClick,
  urlSlug,
  ...restProps
}: MobileMenuItemProps) => {
  const MobileMenuItemClasses = CN(`w-full px-4 py-[14px] rounded`, className)

  return (
    <li
      tabIndex={0}
      data-component-id={`${componentId}-section`}
      className={MobileMenuItemClasses}
      onClick={onMainMenuItemClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onMainMenuItemClick?.()
        }
      }}
      {...restProps}>
      <div className='flex w-full'>
        <div className={CN('flex items-between w-full text-N-700 gap-x-2', menuItemClassName)}>
          <div className='w-5 h-5'>
            {iconBefore && (
              <i
                data-component-id={`${componentId}-section-before-icon`}
                className={`${iconBefore} ${iconBeforeClassName}`}
              />
            )}
          </div>

          <span data-component-id={`${componentId}-section-item-name`} className={'font-medium'}>
            {urlSlug ? (
              <Link href={`/category/${urlSlug}`} passHref>
                {menuItemName}
              </Link>
            ) : (
              menuItemName
            )}
          </span>
        </div>

        {isIconAfter && (
          <i
            data-component-id={`${componentId}-section-after-icon`}
            className={`nzsbi-chevron-right ${iconAfterClassName}`}
          />
        )}
      </div>
    </li>
  )
}

export default MobileMenuItem
