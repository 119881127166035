import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetProductDetailsImage {
  isDefulat: boolean
  id: number
  url: string
  alt: string
  title: string
  displayorder: number
}

export interface IGetProductDetailsSpecification {
  id: string
  specTerm: string
  specDescription: string
  displayOrder: number
}

export interface IGetProductDetailsAttribute {
  id: string
  type: string
  code: string
  description: string
  value: string
}

export interface IProductVariation {
  id: number
  colorCode: string
  colorValues?: Array<any>
  colorDescription?: string
  sizeCode?: string
  sizeDescription?: string
  sku: string
  customerSku?: string
  price: number
  priceWithGst: number
  isRestricted: boolean
  isCatalogue: boolean
  isDeal: boolean
  isTemporaryUnavailable: boolean
  isClearance: boolean
  displayOrder: number
  stock: number
  expectedDeliveryDate: string
  pickUpStores: number
  images: Array<IGetProductDetailsImage>
  variantFlag: 0
}

export interface IGetProductDetailsResponse {
  messageTitle?: string
  messageDescription?: string
  messageType?: string
  messageCode?: string
  data: {
    metaTitle: string
    metaDescription: string
    images: Array<IGetProductDetailsImage>
    specifications: Array<IGetProductDetailsSpecification>
    description: {
      content: string
      features: Array<{
        id: string
        feature: string
        displayOrder: number
      }>
    }
    searchWords: Array<{
      id: string
      searchWord: string
    }>
    linkTypes: Array<{
      name: string
      count: number
      linkModels: Array<{
        id: string
        link: string
        description: string
        linkType: string
      }>
    }>
    attributes: Array<IGetProductDetailsAttribute>
    variants: Array<IProductVariation>
    specialPrice: number
    savePrice: number
    priceWithGst: number
    isFavorite: boolean
    isPriorirty: boolean
    stock: number
    customerSKU?: string
    expectedDeliveryDate: string
    pickUpStores: number
    price: number
    balance?: number
    isDeal: boolean
    isClearance: boolean
    isRestricted: boolean
    isTemporaryUnavailable: boolean
    isNewlyAdded: boolean
    isCatalogue: boolean
    isEnrolled: boolean
    hasVariant: boolean
    productFlag: 1 | 2 | 3 | 4
    image: IGetProductDetailsImage
    id: number
    sku: string
    title: string
    urlSlug: string
  }
}

/* API Request */
export const getProductDetails = async (req: any) => {
  const { data: response } = await httpGET({
    url: `/products/getdetails/${req.slug}`,
    context: req?.context
  })
  return response as IGetProductDetailsResponse
}

/* Client side react-query custom hook */
export const useGetProductDetails = () => {
  const { refetch: productDetails, ...restOptions } = useQuery({
    queryKey: ['product-details'],
    queryFn: getProductDetails
  })

  // Passing mutateAsync as link To CreditAccount
  // and restOptions as rest of the options
  return { productDetails, ...restOptions }
}
