import React, { ChangeEvent, FC } from 'react'
import { TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IPhoneInputProps {
  className?: string
  inputSize?: 'sm' | 'md'
  inputWrapperClassName?: string
  label?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  componentId?: string
  [x: string]: any
}

export const PhoneInput: FC<IPhoneInputProps> = ({
  className,
  inputSize = 'md',
  inputWrapperClassName,
  label,
  onChange,
  componentId,
  ...restProps
}: IPhoneInputProps) => {
  const PhoneInputClasses = CN(`phone-input relative`, className)
  return (
    <div className={PhoneInputClasses}>
      <TextField
        componentId={componentId}
        inputSize={inputSize}
        label={label}
        onChange={event => {
          const regex = /^[0-9\b]+$/
          if ((event.target.value === '' || regex.test(event.target.value)) && onChange) {
            onChange(event)
          }
        }}
        wrapperClassName={CN('w-full', inputWrapperClassName)}
        {...restProps}
      />
    </div>
  )
}

export default PhoneInput
