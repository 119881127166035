import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { Button, MultiSelect, Select } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { changeRouter } from 'lib/utilities'

export enum RedirectionTypes {
  REDIRECTION_301 = 301,
  REDIRECTION_302 = 302,
  REDIRECTION_307 = 307,
  REDIRECTION_308 = 308
}

export interface URLRedirectionFilterSectionProps {
  className?: string
  componentId?: string
  onClickReset?: () => void
}

export const URLRedirectionFilterSection: FC<URLRedirectionFilterSectionProps> = ({
  className,
  componentId,
  onClickReset
}: URLRedirectionFilterSectionProps) => {
  const URLRedirectionFilterSectionClasses = CN(
    'w-full h-fit flex flex-col p-6 bg-white rounded border border-[#D9D9D5] gap-y-6',
    className
  )
  const params = useSearchParams()
  const router = useRouter()

  const STATUSES = 'statuses'
  const CLOUDFLARE_STATUS = 'cloudflareStatus'
  const PAGE = 'page'

  const statusesParam = params.get(STATUSES) ?? undefined
  const cloudflareStatusesParam = params.get(CLOUDFLARE_STATUS) ?? undefined

  const [status, setStatus] = useState(
    statusesParam?.split(',').map(type => ({
      label: type,
      value: type
    }))
  )

  const [cloudflareStatus, setCloudflareStatus] = useState(
    cloudflareStatusesParam?.split(',').map(type => ({
      label: type,
      value: type
    }))
  )

  const onResetClick = () => {
    onClickReset?.()
    setStatus([])
    setCloudflareStatus([])
    changeRouter(router, undefined, undefined, [STATUSES, CLOUDFLARE_STATUS, PAGE])
  }

  const STATUS_OPTIONS = [
    { label: RedirectionTypes.REDIRECTION_301, value: RedirectionTypes.REDIRECTION_301 },
    { label: RedirectionTypes.REDIRECTION_302, value: RedirectionTypes.REDIRECTION_302 },
    { label: RedirectionTypes.REDIRECTION_307, value: RedirectionTypes.REDIRECTION_307 },
    { label: RedirectionTypes.REDIRECTION_308, value: RedirectionTypes.REDIRECTION_308 }
  ]

  const CLOUDFLARE_STATUS_OPTIONS = [
    { label: 'Pending - Add', value: 0 },
    { label: 'Pending - Delete', value: 1 },
    { label: 'Completed', value: 2 }
  ]

  return (
    <div className='flex flex-col gap-6'>
      <div className={URLRedirectionFilterSectionClasses}>
        <div className='flex w-full justify-between items-center gap-x-3'>
          <p className='font-700 text-base text-N-700'>Filters</p>
          <Button
            componentId={`${componentId}-filter-section-resetAllFilters`}
            iconBefore='nzsbi-cycle text-[24px]'
            size='md'
            appearance='link-gray'
            onClick={() => {
              onResetClick()
            }}>
            Reset All Filters
          </Button>
        </div>

        <div className={CN('grid grid-cols-1 md:!grid-cols-2 gap-4 lg:!grid-cols-4')}>
          <div className='w-full'>
            <MultiSelect
              className='w-full'
              label='Status'
              componentId={`${componentId}-status-select`}
              value={status}
              options={STATUS_OPTIONS}
              onChange={(types: any) => {
                setStatus(types)
                if (types?.length > 0) {
                  const typesValues = types.map((type: any) => type.value)
                  changeRouter(router, STATUSES, typesValues.join(','), [PAGE])
                } else {
                  changeRouter(router, undefined, undefined, [STATUSES, PAGE])
                }
              }}
            />
          </div>
          <div className='w-full'>
            <Select
              className='w-full'
              label='Cloudflare Status'
              componentId={`${componentId}-cloudflare-status-select`}
              value={cloudflareStatus}
              options={CLOUDFLARE_STATUS_OPTIONS}
              placeholder={<span className='text-N-700'>All</span>}
              onChange={(types: any) => {
                setCloudflareStatus(types)

                if (types) {
                  changeRouter(router, CLOUDFLARE_STATUS, types?.label?.toUpperCase(), [PAGE])
                } else {
                  changeRouter(router, undefined, undefined, [CLOUDFLARE_STATUS, PAGE])
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default URLRedirectionFilterSection
