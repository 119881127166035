import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useMemo } from 'react'
import { AlertProps, Button, EmptyRecords, Lozenge, LozengeProps, Toast } from '@nzsb/shopnx-ui'
import { IURLRedirection, useDeleteURLRedirection } from 'lib/actions'
import { cloudflareStatusData } from 'lib/static'
import { changeRouter } from 'lib/utilities'

import { DataTable } from 'components/molecules'

export interface URLRedirectionDataTableProps {
  componentId?: string
  redirectionItems: {
    representations: IURLRedirection[]
    pageIndex: number
    pageSize: number
    resultCount: number
    totalPages: number
  }
  isLoading?: boolean
}

export const URLRedirectionDataTable: FC<URLRedirectionDataTableProps> = ({
  componentId,
  redirectionItems,
  isLoading
}: URLRedirectionDataTableProps) => {
  const router = useRouter()
  const params = useSearchParams()

  const pageSizeParam = params.get('pageSize') ?? '30'
  const notify = (props: AlertProps) => Toast(props)

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50 min-w-[153px] text-left`

  const { mutateAsync: deleteURLRedirectionAsync, isPending: isLoadingDeleteURL } =
    useDeleteURLRedirection()

  const handleDeleteURLRedirection = async (id: string) => {
    try {
      const data = await deleteURLRedirectionAsync(id)

      notify({
        alertHeader: data.messageTitle,
        alertBody: data.messageDescription ?? '',
        status: data.messageType as 'Success' | 'Error'
      })
    } catch (error) {
      notify({
        alertHeader: 'Error',
        alertBody: 'An error occurred while deleting the URL redirection.',
        status: 'Error'
      })
    }
  }

  const getActiveParameters = (rowParams: any) => {
    const activeParams = []

    if (rowParams?.preserveQueryString) {
      activeParams.push('Preserve query string')
    }

    if (rowParams?.subPathMapping) {
      activeParams.push('Subpath matching')
    }

    if (rowParams?.preservePathSuffix) {
      activeParams.push('Preserve path suffix')
    }

    return activeParams.length ? (
      <ul className='text-N-600 lg:text-xs'>
        {activeParams.map((parameter: string) => (
          <li key={parameter}>- {parameter}</li>
        ))}
      </ul>
    ) : (
      '-'
    )
  }

  const getCellContent = (row: any, rowHeader: string) => {
    switch (rowHeader) {
      case 'Bulk Number':
        return (
          <span className='text-N-600 font-500 text-xs self-start'>
            {row?.original?.bulkNumber}
          </span>
        )
      case 'Old URL':
        return (
          <a className='text-O-500 underline break-all text-xs' href={`${row?.original?.oldUrl}`}>
            {row?.original?.oldUrl}
          </a>
        )
      case 'New URL':
        return (
          <a className='text-O-500 underline break-all text-xs' href={`${row?.original?.newUrl}`}>
            {row?.original?.newUrl}
          </a>
        )
      case 'Status Code':
        return <div className='text-N-600 font-500 text-sm'>{row?.original?.statusCode}</div>
      case 'Active Parameters':
        return <div>{getActiveParameters(row?.original)}</div>
      case 'Method':
        return row?.original?.isManual ? (
          <Lozenge appearance='outline' variant='warning-2'>
            MANUAL
          </Lozenge>
        ) : (
          <Lozenge appearance='outline' variant='success'>
            AUTOMATIC
          </Lozenge>
        )
      case 'Cloudflare Status':
        return (
          <Lozenge
            appearance='subtle'
            variant={
              cloudflareStatusData[
                row.original.cloudflareStatus as keyof typeof cloudflareStatusData
              ]?.variant as LozengeProps['variant']
            }>
            {
              cloudflareStatusData[
                row.original.cloudflareStatus as keyof typeof cloudflareStatusData
              ]?.label
            }
          </Lozenge>
        )
      case 'Action':
        return (
          <div className='flex items-start max-w-[100px]'>
            <Button
              appearance='secondary-orange'
              disabled={row?.original?.cloudflareStatus !== 2}
              iconOnly
              iconBefore='nzsbi-delete text-[20px]'
              componentId='url-redirection-delete-button'
              className='!px-2'
              size='sm'
              isLoading={isLoadingDeleteURL}
              onClick={() => {
                handleDeleteURLRedirection(row.original.id)
              }}
            />
          </div>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col text-sm font-sans py-2 px-4 w-full'>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Bulk Number:</span>
              <span className='text-N-600 font-500'>{row?.original?.bulkNumber}</span>
            </div>
            <div className='flex items-start py-1 self-stretch'>
              <div className='min-w-[108px] text-N-500 font-400'>Old URL:</div>
              <a
                className='text-O-500 underline break-words max-w-full'
                href={`${row?.original?.oldUrl}`}>
                {row?.original?.oldUrl}
              </a>
            </div>
            <div className='flex items-center py-1'>
              <span className='min-w-[108px] text-N-500 font-400'>New URL:</span>
              <a
                className='text-O-500 underline break-words max-w-full'
                href={`${row?.original?.newUrl}`}>
                {row?.original?.newUrl}
              </a>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Status Code:</span>
              <span className='text-N-600 font-500'>{row?.original?.statusCode}</span>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Active Parameters:</span>
              <div>{getActiveParameters(row?.original)}</div>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Method:</span>
              {row?.original?.isManual ? (
                <Lozenge appearance='outline' variant='warning-2'>
                  MANUAL
                </Lozenge>
              ) : (
                <Lozenge appearance='outline' variant='success'>
                  AUTOMATIC
                </Lozenge>
              )}
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Cloudflare Status:</span>
              <Lozenge
                appearance='subtle'
                variant={
                  cloudflareStatusData[
                    row.original.cloudflareStatus as keyof typeof cloudflareStatusData
                  ]?.variant as LozengeProps['variant']
                }>
                {
                  cloudflareStatusData[
                    row.original.cloudflareStatus as keyof typeof cloudflareStatusData
                  ]?.label
                }
              </Lozenge>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Action:</span>
              <Button
                appearance='secondary-orange'
                disabled={row?.original?.cloudflareStatus !== 2}
                iconOnly
                componentId='url-redirection-delete-button'
                iconBefore='nzsbi-delete text-[20px]'
                className='!px-2'
                size='sm'
                isLoading={isLoadingDeleteURL}
                onClick={() => {
                  handleDeleteURLRedirection(row.original.id)
                }}
              />
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const DESKTOP_COLUMNS = useMemo(() => {
    const columnData = [
      {
        header: 'Bulk Number',
        label: 'Bulk Number',
        accessor: 'bulkNumber',
        cellClassName: 'break-all !min-w-[107px] !max-w-[107px]'
      },
      {
        accessor: 'oldUrl',
        header: 'Old URL',
        label: 'Old URL',
        cellClassName: 'break-all !max-w-[297px]'
      },
      {
        header: 'New URL',
        accessor: 'newUrl',
        cellClassName: 'break-all !max-w-[297px]',
        label: 'New URL'
      },
      {
        accessor: 'statusCode',
        header: 'Status Code',
        label: 'Status Code',
        cellClassName: '!min-w-[102px]'
      },
      {
        header: 'Active Parameters',
        label: 'Active Parameters',
        accessor: 'activeParameters',
        cellClassName: '!w-[172px]'
      },
      {
        header: 'Method',
        label: 'Method',
        accessor: 'method',
        cellClassName: '!min-w-[98px] !max-w-[98px]'
      },
      {
        accessor: 'cloudflareStatus',
        header: 'Cloudflare Status',
        label: 'Cloudflare Status',
        cellClassName: '!min-w-[132px] !max-w-[132px]'
      },
      {
        header: 'Action',
        accessor: 'action',
        label: 'Action',
        cellClassName: '!min-w-[100px] !max-w-[100px]'
      }
    ]

    return columnData.map(({ header, accessor, label, cellClassName = '' }) => ({
      Header: header,
      accessor: accessor,
      cellHeaderClassName: `${cellHeaderClassName} ${cellClassName}`,
      cellClassName: 'h-[64px] align-top',
      Cell: ({ row }: any) => getCellContent(row, label)
    }))
  }, [redirectionItems, redirectionItems?.pageIndex, redirectionItems?.pageSize])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [redirectionItems, redirectionItems?.pageIndex, redirectionItems?.pageSize])

  const tableProps = {
    enablePagination: true,
    data: redirectionItems?.representations || [],
    showingCount: redirectionItems?.representations?.length || 0,
    totalCount: redirectionItems?.resultCount,
    onPerPageChange: (perPage: number) => {
      changeRouter(router, 'pageSize', perPage.toString(), ['page'])
    },
    defaultSelectedOption: pageSizeParam,
    perPageSelections: ['30', '60', '90'],
    paginationProps: {
      pageIndex: redirectionItems?.pageIndex,
      pageSize: redirectionItems?.pageSize,
      resultsCount: redirectionItems?.resultCount,
      totalPages: redirectionItems?.totalPages,
      limitedPerPageCount: true,
      onPageChange: (index: number) => {
        changeRouter(router, 'page', index.toString())
      }
    },
    enableRowSelection: false
  }

  return (
    <div className='flex w-full h-fit' data-component-id={`order-history-table-${componentId}`}>
      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no invoices/credits to display.
            </span>
          </div>
        }
        {...tableProps}
      />
      {/* Desktop View */}
      <DataTable
        mainDivClassName='hidden lg:!block'
        columns={DESKTOP_COLUMNS}
        {...tableProps}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no invoices/credits to display.
            </span>
          </div>
        }
      />
    </div>
  )
}

export default URLRedirectionDataTable
