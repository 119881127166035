import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { OctagonWrapper } from 'components/atoms'

export interface ISpecialServicesCardProps {
  cardTitle: string
  childBlocks?: Array<{
    __typename: string
    Header: string
  }>
  className?: string
  icon: string
  urlSlug: string
}

export const SpecialServicesCard: FC<ISpecialServicesCardProps> = ({
  cardTitle,
  childBlocks,
  className,
  icon,
  urlSlug,
  ...restProps
}: ISpecialServicesCardProps) => {
  const MyAccountTitleCardClasses = CN(
    `bg-white border-[1px] border-N-100 rounded px-[20px] pb-[24px] w-full h-full cursor-pointer`,
    className
  )

  const router = useRouter()

  const scrollToElement = (element: string) => {
    if (element) {
      const el = document.getElementById(element.replace(/_/g, ' ')) // Convert underscores back to spaces for element IDs
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div
      data-component-id='all-product-category-card'
      className={MyAccountTitleCardClasses}
      {...restProps}>
      {icon && (
        <span>
          {/* Category Pillar tile icon section */}
          <OctagonWrapper
            componentId='all-product-category-card-icon'
            icon={icon}
            className='absolute flex items-center justify-center mt-[-24px]'
          />
        </span>
      )}

      <div className='flex flex-col w-full h-full'>
        {/** Category pillar tile header section */}
        <a
          href={`/services/${urlSlug}`}
          className='text-h3 font-semibold text-N-800 mb-[16px] mt-[60px] capitalize'
          data-component-id={`all-product-category-card-${cardTitle}`}>
          {cardTitle}
        </a>

        {/** Category Pillar tile section */}
        <div
          className='text-base font-normal text-N-700 flex flex-col gap-y-3'
          data-component-id='all-product-category-card-child-categories'>
          {childBlocks?.map(
            (
              childBlock: {
                __typename: string
                Header: string
              },
              subIndex: number
            ) => (
              <Link
                href={{
                  pathname: `/services/${urlSlug}`,
                  query: { section: childBlock?.Header.replace(/\s+/g, '_') } // Convert spaces to underscores
                }}
                onClick={e => {
                  e.preventDefault() // Prevent default immediate navigation

                  // Define a handler to scroll after navigation
                  const handleRouteChange = () => {
                    scrollToElement(childBlock?.Header.replace(/\s+/g, '_'))
                    router.events.off('routeChangeComplete', handleRouteChange) // Cleanup event
                  }

                  router.events.on('routeChangeComplete', handleRouteChange) // Attach event

                  router.push(
                    {
                      pathname: `/services/${urlSlug}`,
                      query: { section: childBlock?.Header.replace(/\s+/g, '_') }
                    },
                    undefined,
                    { scroll: false }
                  )
                }}
                data-component-id={`all-product-category-card-child-categories-${subIndex}`}
                className={CN(`hover:text-O-500`)}
                key={childBlock?.Header ?? subIndex}>
                {childBlock?.Header}
              </Link>
            )
          )}
        </div>

        {/** Category Pillar tile see all btn section */}
        <div className='w-full h-full flex items-end justify-end'>
          <Button
            className='w-fit'
            isBlock={false}
            size='md'
            isRingOffset={false}
            iconAfter='nzsbi-chevron-right'
            onClick={() => {
              router.push(`/services/${urlSlug}`)
            }}
            componentId={`all-product-category-card-see-all-btn`}
            appearance='link'>
            See All
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SpecialServicesCard
