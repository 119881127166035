import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { AlertProps, ProdCard, Toast } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAddToCart, useAddToFavoriteProduct, useDeleteFavoriteProduct } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkRoleBasedProperties } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface IProductCardProps {
  className?: string
  componentId?: string
  product?: any
  appearance?: 'horizontal' | 'vertical'
  isLoggedIn?: boolean
  [key: string]: any
}

/** Render Image with Next Image component */
const RenderImage = (
  appearance: 'horizontal' | 'vertical' | undefined,
  navigateLink: string,
  productData: any
) => {
  return (
    <Link
      href={navigateLink}
      className={CN('relative block w-full', {
        'h-[136px] sm:h-[200px]': appearance === 'vertical',
        'h-[88px] sm:h-[124px]': appearance === 'horizontal'
      })}>
      <NextImage
        imgKey={`${productData?.sku ?? ''}-${productData?.id ?? ''}`}
        width={600}
        height={600}
        imageUrl={productData?.image?.url || 'non-existing-url'}
        alt={productData?.image?.alt || productData?.title}
      />
    </Link>
  )
}

export const ProductCard: FC<IProductCardProps> = ({
  componentId,
  product: initialProduct,
  appearance,
  ...restProps
}: IProductCardProps) => {
  const { expectedDispatchModal, pickUpModal, noStockPickUpModal, bulkOrderModal } =
    useModalContext()

  const router = useRouter()

  const notify = (props: AlertProps) => Toast(props)

  const { open: openDispatchModal, set: setDispatchModalDetails } = expectedDispatchModal
  const { open: openPickupModal, set: setPickupModalDetails } = pickUpModal
  const { open: openNoStockPickupModal, set: setNoStockPickupModalDetails } = noStockPickUpModal
  const { isLoggedIn, productVariants, user } = useAppContext()

  const { addToCartAsync, isPending } = useAddToCart()
  const { mutate: addToFavoriteAction } = useAddToFavoriteProduct()
  const { mutate: deleteFavoriteProduct } = useDeleteFavoriteProduct()

  const [product, setProduct] = useState(initialProduct)

  const { showGstPricePrimary, isAvailabilityAndPickupLocationShow } = checkRoleBasedProperties(
    user?.data?.roles
  )

  const isShowAvailabilitySection = isAvailabilityAndPickupLocationShow && !product?.isEnrolled

  const isShowWishList = isLoggedIn && !product?.isEnrolled

  const addItemsToCart = () => {
    //setIsLoading(true)
    addToCartAsync({ productId: product?.id, quantity: 1 }).then(res => {
      if (res?.data?.validationMessage) {
        notify({
          status: res?.data?.validationMessage?.messageType,
          alertHeader: res?.data?.validationMessage?.title,
          alertBody: res?.data?.validationMessage?.message
        })
      }
    })
  }

  /** On Favorite Action Click */
  const onFavoriteActionClick = () => {
    let updatedProduct = { ...product }

    if (updatedProduct.isFavorite) {
      deleteFavoriteProduct(product?.id)
    } else {
      addToFavoriteAction(product?.id)
    }

    updatedProduct = {
      ...updatedProduct,
      isFavorite: !updatedProduct.isFavorite
    }

    setProduct(updatedProduct)
  }

  useEffect(() => {
    setProduct(initialProduct)
  }, [initialProduct])

  return (
    <ProdCard
      componentId={componentId}
      key={`${product?.sku}-${product?.id}`}
      appearance={appearance}
      productFlag={product?.productFlag}
      nextImage={RenderImage(appearance, `/product/${product?.urlSlug}`, product)}
      lozengeClass='z-10'
      sku={product?.sku}
      priceLabel={{
        price: {
          price: product?.price,
          withGst: product?.priceWithGst,
          saveUpTo: 0.0
        },
        showGstPricePrimary
      }}
      actionButtonText={product?.isEnrolled ? 'Enrol Now' : 'Add to Cart'}
      title={product?.title}
      isShowCatalogIcon={product?.isCatalogue}
      isOnWishlist={product?.isFavorite}
      isAvailabilityAndPickupLocationShow={isShowAvailabilitySection}
      isShowWishList={isShowWishList}
      store={{
        count: product?.pickUpStores
      }}
      stock={product?.stock}
      hasVariant={product?.hasVariant}
      expectedDeliveryDate={product?.expectedDeliveryDate}
      onClickAvailability={() => {
        bulkOrderModal.set(true)
        productVariants.setProduct(product)
      }}
      onClickExpectedDispatch={() => {
        openDispatchModal()
        setDispatchModalDetails({
          sku: product?.sku,
          store: {
            count: product?.pickUpStores
          },
          name: product?.title,
          expectedDispatchDate: product?.expectedDeliveryDate,
          stock: product?.stock,
          image: product?.image
        })
      }}
      onClickPickup={() => {
        if (product?.hasVariant) {
          bulkOrderModal.set(true)
          productVariants.setProduct(product)
        } else if (product?.pickUpStores > 0) {
          openPickupModal()
          setPickupModalDetails({
            sku: product?.sku,
            id: product?.id,
            store: {
              count: product?.pickUpStores
            },
            name: product?.title,
            expectedDispatchDate: product?.expectedDeliveryDate,
            stock: product?.stock,
            image: product?.image
          })
        } else {
          openNoStockPickupModal()
          setNoStockPickupModalDetails({
            sku: product?.sku,
            store: {
              count: product?.pickUpStores
            },
            name: product?.title,
            expectedDispatchDate: product?.expectedDeliveryDate,
            stock: product?.stock,
            image: product?.image
          })
        }
      }}
      isLoading={isPending}
      onClickAddToCart={() => {
        if (product?.isEnrolled) {
          router.push(`/worksafe-training/${product?.urlSlug}`)
        } else if (product?.hasVariant) {
          bulkOrderModal.set(true)
          productVariants.setProduct(product)
        } else {
          addItemsToCart()
        }

        // function (): void {
        //   if (product?.hasVariant) {
        //     bulkOrderModal.set(true)
        //     productVariants.setProduct(product)
        //   } else {
        //     setIsLoading(true)
        //     addToCartAsync({ productId: product?.id, quantity: 1 }).finally(() => {
        //       setIsLoading(false)
        //     })
        //   }
        // }
      }}
      onClickWishlist={() => {
        onFavoriteActionClick()
      }}
      onTitleClickHref={`/product/${product?.urlSlug}`}
      {...restProps}
    />
  )
}

export default ProductCard
