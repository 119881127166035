import { CldImage } from 'next-cloudinary'
//import Image from 'next/image'
import React, { FC } from 'react'
import { getImageUrl } from 'lib/utilities'

export interface INextImageProps {
  className?: string
  alt: string
  height?: any
  imageUrl?: string
  imgKey: string
  nextImgStyle?: any
  width?: any
  useWithDimensions?: boolean
  isZoomView?: boolean
  system?: 'Backend' | 'CMS' | 'General'
  [x: string]: any
}
export const NextImage: FC<INextImageProps> = ({
  className,
  alt,
  height,
  imageUrl,
  imgKey,
  nextImgStyle,
  system = 'Backend',
  width,
  useWithDimensions = false,
  isZoomView = false,
  ...restProps
}: INextImageProps) => {
  const url = getImageUrl({
    imageName: imageUrl ?? '',
    system: system
  })
  const styles = {
    height: '-webkit-fill-available',
    maxHeight: '90vh',
    width: isZoomView ? width - 100 : 'auto'
  }

  return useWithDimensions ? (
    <CldImage
      className={className}
      key={imgKey}
      src={url}
      defaultImage='General-Use/No_Image_Available_New.png'
      alt={alt}
      width={width}
      height={height}
      style={styles}
      placeholder='blur'
      blurDataURL={url}
      sizes='50w'
      {...restProps}
    />
  ) : (
    <CldImage
      key={imgKey}
      src={url}
      defaultImage='General-Use/No_Image_Available_New.png'
      alt={alt}
      fill
      style={{ objectFit: 'scale-down', ...nextImgStyle }}
      placeholder='blur'
      blurDataURL={url}
      sizes='50w'
      {...restProps}
    />
  )
}

export default NextImage
