import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IPdfAvailabilityRequest {
  invoiceList: Array<string>
}

export interface IPdfAvailabilityResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IPdfAvailability
}

export interface IPdfAvailability {
  mergeFileDetails: [
    {
      invoice: string
      avalibility: boolean
    }
  ]
}

export const getPdfAvailability = async (req: IPdfAvailabilityRequest) => {
  const response = await httpPOST({
    url: `invoice/pdf_availability`,
    body: req
  })
  return response?.data as IPdfAvailabilityResponse
}

export const useGetPdfAvailability = () => {
  return useMutation({
    mutationFn: getPdfAvailability
  })
}
