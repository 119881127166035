import React, { type FC } from 'react'
import type { IInvoiceDetailsAddress, IInvoicePickupDetails } from 'lib/actions'

import { InvoiceDetailsAddressCard } from './InvoiceDetailsAddressCard'

export interface InvoiceDetailsAddressSectionProps {
  invoiceAddress?: IInvoiceDetailsAddress
  pickupDetails?: IInvoicePickupDetails
  shippingAddress?: IInvoiceDetailsAddress
}

export const InvoiceDetailsAddressSection: FC<InvoiceDetailsAddressSectionProps> = ({
  invoiceAddress,
  pickupDetails,
  shippingAddress
}: InvoiceDetailsAddressSectionProps) => {
  const getFullName = (firstName: string, lastName: string) => {
    if (!firstName && !lastName) return ''
    if (!firstName) return lastName
    if (!lastName) return firstName
    return `${firstName} ${lastName}`
  }

  return (
    <>
      {(shippingAddress || invoiceAddress || pickupDetails) && (
        <div className='flex flex-col gap-[24px] md:!grid md:!grid-cols-2 md:gap-[32px] w-full'>
          {invoiceAddress && (
            <InvoiceDetailsAddressCard
              header='Invoice Address'
              componentId='invoice-details-invoice-address'
              companyName={
                invoiceAddress?.businessName ||
                invoiceAddress?.attentionTo ||
                getFullName(invoiceAddress?.firstName, invoiceAddress?.lastName)
              }
              name={
                invoiceAddress?.attentionTo ||
                getFullName(invoiceAddress?.firstName, invoiceAddress?.lastName)
              }
              streetAddress={invoiceAddress?.streetAddress}
              suburb={invoiceAddress?.suburb}
              city={invoiceAddress?.city}
              postCode={invoiceAddress?.postCode}
            />
          )}
          {shippingAddress && (
            <InvoiceDetailsAddressCard
              header='Shipping Address'
              componentId='invoice-details-shipping-address'
              companyName={
                shippingAddress?.businessName ||
                shippingAddress?.attentionTo ||
                getFullName(shippingAddress?.firstName, shippingAddress?.lastName)
              }
              name={
                shippingAddress?.attentionTo ||
                getFullName(shippingAddress?.firstName, shippingAddress?.lastName)
              }
              streetAddress={shippingAddress?.streetAddress}
              suburb={shippingAddress?.suburb}
              city={shippingAddress?.city}
              postCode={shippingAddress?.postCode}
            />
          )}
          {pickupDetails && (
            <InvoiceDetailsAddressCard
              header='Pick Up'
              componentId='invoice-details-pickup-details'
              companyName={pickupDetails?.branchName}
              streetAddress={pickupDetails?.streetAddress}
              suburb={pickupDetails?.suburb}
              city={pickupDetails?.city}
              postCode={pickupDetails?.postCode}
              collectPersonDetails={{
                firstName: pickupDetails?.pickupPersonFirstName,
                lastName: pickupDetails?.pickupPersonLastName,
                emailAddress: pickupDetails?.pickupPersonEmailAddress,
                contactNumber: pickupDetails?.pickupPersonContactNumber
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default InvoiceDetailsAddressSection
