import { deleteCookie } from 'cookies-next'

export const CART_SESSION_VALIDITY = 60 * 24 * 14 // in minutes
export const CART_SESSION_COOKIE_NAME = '_cartSessionId'

export const CART_ID_COOKIE_NAME = '_cartId'
/**
 * Clear the cart session Id
 */
export const deleteCartSession = () => {
  deleteCookie(CART_SESSION_COOKIE_NAME)
}

const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const createCartSession = () => {
  const expires = new Date(new Date().getTime() + CART_SESSION_VALIDITY * 60000)
  const sessionId = generateUUID()
  return { sessionId, expires }
}

export const getStockMessage = (stock: number) => {
  return stock > 1
    ? `Only ${stock} items available in stock`
    : `Only ${stock} item available in stock`
}
