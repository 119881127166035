export const preciseMultiply = (numbers: number[]) => {
  // Initialize total decimals and result
  let totalDecimals = 0
  let result = 1

  numbers.forEach(num => {
    // Convert number to string and count decimals
    const numStr = num.toString()
    const decimals = (numStr.split('.')[1] || '').length

    // Update total decimals
    totalDecimals += decimals

    // Scale number to integer and multiply
    result *= Math.round(num * Math.pow(10, decimals))
  })

  // Scale back by dividing with total decimal factor
  return result / Math.pow(10, totalDecimals)
}

export default preciseMultiply
