import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Button, Drawer, Dropdown, NZLogo } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkURL } from 'lib/utilities'

import {
  MobileMenuItem,
  MobileMenuProductPillarMenu,
  MobileMenuSubItem,
  MyAccountDropdown
} from 'components/molecules'

export interface IHeaderDefaultProps {
  /** Catalogues product Drop Button */
  Catalogues?: ReactNode
  className?: string
  /** handle about us page */
  handleAboutUsBtn?: () => any
  /** handle services page */
  handleAllServices?: () => any
  /** handle help page */
  handleHelpBtn?: () => any
  /** handle store locator page */
  handleStoreLocatorBtn?: () => any
  /** Intelligent (main) Search handle */
  IntelligentSearch?: ReactNode
  /** Mega Menu */
  MegaMenu?: ReactNode
  /** Multi Side Ordering page */
  MultiSideOrders?: ReactNode
  /** My Account Drop down */
  MyAccount?: ReactNode
  /** login form Drop down */
  OnLogin?: ReactNode
  /** Registration Page Btn */
  OnRegister?: ReactNode
  /** Promotion Drop Down Button */
  Promotions?: ReactNode
  /** Quick Cart Drop down */
  QuickCart?: ReactNode
  logout?: any
}

export const HeaderDefault: FC<IHeaderDefaultProps> = ({
  Catalogues,
  className,
  handleAboutUsBtn,
  handleAllServices,
  handleHelpBtn,
  handleStoreLocatorBtn,
  IntelligentSearch,
  MegaMenu,
  MultiSideOrders = true,
  MyAccount,
  OnLogin,
  OnRegister,
  Promotions,
  QuickCart,
  logout,
  ...restProps
}: IHeaderDefaultProps) => {
  const HeaderClasses = CN(`header`, className)
  const router = useRouter()

  const { mainMenuSidePane } = useModalContext()

  const [subItemsList, setSubItemsList] = useState<{ subMenuItemName: string; url: string }[]>([])

  /** logout hook */

  const { isSessionLoading, isLoggedIn } = useAppContext()

  const { loginModal, signUpModal, expandSearch } = useModalContext()

  /** Level wise side pane body content switch state */
  const [mobileMenuItemsDetails, setMobileMenuItemsDetails] = useState({
    level: 1,
    selectedItem: ''
  })

  useEffect(() => {
    setMobileMenuItemsDetails({
      level: 1,
      selectedItem: ''
    })
  }, [!mainMenuSidePane?.isOpen])

  /** Main Side Pane Menu Items */
  const MobileMenuItems = {
    section_1: [
      {
        iconBefore: 'nzsbi-box-2',
        menuItemName: 'Products',
        isIconAfter: true,
        hasSubmenu: true,
        url: null
      },
      {
        iconBefore: 'nzsbi-cog-2',
        menuItemName: 'Services',
        isIconAfter: true,
        hasSubmenu: false,
        url: '/services'
      }
    ],
    section_2: [
      {
        iconBefore: 'nzsbi-news-2',
        menuItemName: 'Promotions',
        isIconAfter: true,
        hasSubmenu: true,
        url: null
      },
      {
        iconBefore: 'nzsbi-catalogue',
        menuItemName: 'Catalogues',
        isIconAfter: true,
        hasSubmenu: true,
        url: null
      },
      {
        iconBefore: 'nzsbi-location-pin',
        menuItemName: 'Store Locator',
        isIconAfter: false,
        hasSubmenu: false,
        url: '/store-locator'
      },
      {
        iconBefore: 'nzsbi-question-double-lined-rounded',
        menuItemName: 'Help',
        isIconAfter: false,
        hasSubmenu: false,
        url: 'https://crm.nzsafetyblackwoods.co.nz/contactus'
      },
      {
        iconBefore: 'nzsbi-exclamation-1',
        menuItemName: 'About Us',
        isIconAfter: false,
        hasSubmenu: false,
        url: '/about-us'
      }
    ]
  }

  const MobileMenuSubItems = {
    promotions: [
      {
        subMenuItemName: 'Current Promotions',
        url: '/current-promotions'
      },
      {
        subMenuItemName: 'Deals',
        url: '/deals'
      },
      {
        subMenuItemName: 'Clearance',
        url: '/clearance'
      },
      {
        subMenuItemName: 'Brands',
        url: '/brands/shop-by-brands'
      }
    ],
    catalogues: [
      {
        subMenuItemName: 'Product Range Catalogue',
        url: 'https://nexuspublications.com.au/a10/publications/home/6766?toolbar=no&portrait=false'
      },
      {
        subMenuItemName: 'Expert Advice',
        url: 'https://nexuspublications.com.au/a10/publications/home/1316?toolbar=no&portrait=false'
      }
    ]
  }

  useEffect(() => {}, [])

  return (
    <div data-component-id='header-component w-full' className={HeaderClasses} {...restProps}>
      {!isLoggedIn && (
        <div className='bg-[#3D3D3C]'>
          <div className='container flex items-center text-white h-[48px]'>
            <div className='flex justify-end w-full'>
              <div className='flex gap-[8px]'>
                {!isLoggedIn && !isSessionLoading && (
                  <>
                    {/** LogIn Drop down button */}
                    <Button
                      componentId='top-bar-sign-in'
                      onClick={() => loginModal?.set(!loginModal?.isOpen)}
                      appearance='primary-orange'
                      size='xs'
                      className='!h-[32px]'>
                      SIGN IN
                    </Button>
                    {/** Register page Btn */}
                    <Button
                      componentId='top-bar-create-account'
                      appearance='tertiary-orange'
                      size='xs'
                      className='!h-[32px] !bg-[#3D3D3C] hover:!bg-N-600'
                      contentClassName='!text-white'
                      onClick={() => signUpModal?.set(!signUpModal?.isOpen)}>
                      CREATE ACCOUNT
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/** Header Top */}
      <div className='bg-B-500'>
        <div className='container flex items-center text-white h-[60px] md:h-[68px] lg:h-[76px] justify-between gap-[116px]'>
          <div className='flex gap-[40px] items-center'>
            <div className='flex gap-[16px] items-center w-full'>
              {/** Toggle Menu Button */}
              <Button
                componentId='top-bar-menu'
                iconOnly
                onClick={() => {
                  mainMenuSidePane.open()
                }}
                size='xs'
                className='text-white focus:ring-offset-0 focus:ring-0 focus:shadow-none w-[40px] h-[40px] px-[16px] flex justify-center hover:!bg-B-600 md:!hidden'
                appearance='link-gray'
                iconBefore='nzsbi-menu-fill'
              />

              {/** Main Menu Side Pane Drawer */}
              <Drawer
                componentId='main-menu-side-pane-drawer'
                disableHeader={true}
                overlayClassName='block md:hidden'
                className='!w-[340px] block md:hidden'
                onOverlayClick={() => {
                  mainMenuSidePane?.close()
                  setMobileMenuItemsDetails({
                    ...mobileMenuItemsDetails,
                    level: 1,
                    selectedItem: ''
                  })
                }}
                headerTitle='All Product Categories'
                show={mainMenuSidePane?.isOpen}
                direction='left'
                animationType='slideFromLeft'
                disableFooter>
                <div className='flex flex-col p-[24px] w-full'>
                  {/** Main Menu Close Button */}
                  <i
                    tabIndex={0}
                    role='button'
                    data-component-id='main-menu-side-pane-drawer-close-btn'
                    className='nzsbi-close text-[20px] text-N-700 cursor-pointer flex justify-end'
                    onClick={() => {
                      mainMenuSidePane?.close()
                      setMobileMenuItemsDetails({
                        ...mobileMenuItemsDetails,
                        level: 1,
                        selectedItem: ''
                      })
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        mainMenuSidePane?.close()
                        setMobileMenuItemsDetails({
                          ...mobileMenuItemsDetails,
                          level: 1,
                          selectedItem: ''
                        })
                      }
                    }}
                  />

                  {mobileMenuItemsDetails?.level === 1 && (
                    <>
                      {/** Main Menu Title */}
                      <span
                        data-component-id='main-menu-side-pane-drawer-header'
                        className='font-600 text-h3 text-black'>
                        Main Menu
                      </span>

                      {/** Main Menu Items Section 1 */}
                      <ul className='flex flex-col gap-y-3 mt-[34px] list-none'>
                        {MobileMenuItems?.section_1?.map((item: any, index: number) => (
                          <MobileMenuItem
                            key={`${item.url}-mobile-menu-item`}
                            componentId={`main-menu-section_1-item-${index}`}
                            className='bg-N-25'
                            iconBefore={item.iconBefore}
                            isIconAfter={item.isIconAfter}
                            iconBeforeClassName='text-[24px] text-N-700'
                            menuItemName={item.menuItemName}
                            menuItemClassName='w-[198px] font-500 text-base text-N-700 text-left'
                            iconAfterClassName='text-[22px] text-N-400'
                            onMainMenuItemClick={() => {
                              if (item?.hasSubmenu) {
                                setMobileMenuItemsDetails({
                                  ...mobileMenuItemsDetails,
                                  level: 2,
                                  selectedItem: item.menuItemName
                                })
                              } else {
                                mainMenuSidePane?.close()
                                router.push(item.url)
                              }
                            }}
                          />
                        ))}
                      </ul>

                      {/** Main Menu Items Section 2 */}
                      <ul className='flex flex-col gap-y-3 mt-[46px] list-none'>
                        {MobileMenuItems?.section_2?.map((item: any, index: number) => (
                          <MobileMenuItem
                            key={`${item.url}-menu-item`}
                            componentId={`main-menu-section_2-item-${index}`}
                            className='bg-N-25'
                            iconBefore={item.iconBefore}
                            isIconAfter={item.isIconAfter}
                            iconBeforeClassName='text-[24px] text-N-700'
                            menuItemName={item.menuItemName}
                            menuItemClassName='w-[198px] font-500 text-base text-N-700 text-left'
                            iconAfterClassName='text-[22px] text-N-400'
                            onMainMenuItemClick={() => {
                              if (item?.hasSubmenu) {
                                setMobileMenuItemsDetails({
                                  ...mobileMenuItemsDetails,
                                  level: 2,
                                  selectedItem: item.menuItemName
                                })
                                if (item.menuItemName === 'Promotions') {
                                  setSubItemsList(MobileMenuSubItems?.promotions)
                                }
                                if (item.menuItemName === 'Catalogues') {
                                  setSubItemsList(MobileMenuSubItems?.catalogues)
                                }
                              } else {
                                mainMenuSidePane?.close()
                                // check if url is same
                                const { isInsideUrl } = checkURL(item.url)
                                if (isInsideUrl) {
                                  router.push(item.url)
                                } else {
                                  window.open(item?.url, '_blank', 'noopener')
                                }
                              }
                            }}
                          />
                        ))}
                      </ul>
                    </>
                  )}

                  {mobileMenuItemsDetails?.level === 2 && (
                    <>
                      {/** Middle Level Menu Items */}
                      {(mobileMenuItemsDetails?.selectedItem === 'Catalogues' ||
                        mobileMenuItemsDetails?.selectedItem === 'Promotions') && (
                        <MobileMenuSubItem
                          componentId='main-menu-side-pane-drawer-middle-level-sub-items'
                          selectedMainMenuItem={mobileMenuItemsDetails?.selectedItem}
                          mobileMenuSubItems={MobileMenuSubItems}
                          mobileMenuItemsDetails={mobileMenuItemsDetails}
                          subItemsList={subItemsList}
                          onBackClick={() => {
                            setMobileMenuItemsDetails({
                              level: 1,
                              selectedItem: ''
                            })
                          }}
                        />
                      )}
                    </>
                  )}

                  {mobileMenuItemsDetails?.selectedItem === 'Products' && (
                    <MobileMenuProductPillarMenu
                      onBackClick={() => {
                        setMobileMenuItemsDetails({
                          level: 1,
                          selectedItem: ''
                        })
                      }}
                      mobileMenuItemsDetails={mobileMenuItemsDetails}
                      componentId='mobile-main-menu-side-pane-drawer-products'
                    />
                  )}
                </div>
              </Drawer>

              {/** NZ Blackwood Logo */}
              <NZLogo
                data-component-id='header-logo'
                className={CN('!h-[28px] lg:!h-[44px] cursor-pointer lg:!w-[500px]')}
                appearance='primary'
                variant='stacked'
                onClick={() => {
                  router.push('/')
                }}
              />
            </div>
            <div className='hidden md:!flex md:gap-[16px]'>
              {/** Mega menu component */}
              {MegaMenu}
              {/** Service page handle */}
              <Button
                componentId='top-bar-services'
                className='focus:ring-offset-0 focus:ring-0 focus:shadow-none hover:!bg-B-600'
                size='xs'
                iconBefore='h-5 w-5 flex items-center justify-center nzsbi-cog-2'
                onClick={handleAllServices}>
                Services
              </Button>
            </div>
          </div>
          <div
            className={CN(
              'flex items-center justify-end gap-[8px] lg:gap-[32px] max-w-[733px] lg:w-full',
              {}
            )}>
            {/** intelligent Search */}
            {/** Tablet and Mobil Search Button for Intelligent(main) Search */}

            <Button
              componentId='top-bar-search'
              className='text-white focus:ring-offset-0 focus:ring-0 focus:shadow-none w-[40px] h-[40px] hover:!bg-B-600 block lg:!hidden'
              size='xs'
              appearance='link-gray'
              iconBefore='nzsbi-search'
              onClick={() => {
                expandSearch?.set(!expandSearch?.isOpen)
              }}
              iconOnly
            />

            <div className='w-full hidden lg:!block'> {IntelligentSearch}</div>

            <div className='flex gap-[8px]'>
              {/** My Account Drop Down */}
              {isLoggedIn && !isSessionLoading && <MyAccountDropdown logout={logout} />}
              {/** Quick Card Drop Down */}
              {QuickCart}
            </div>
          </div>
        </div>
      </div>

      {/** Sub Header, When Mobile View this bar is disappear */}
      <div className='bg-[#3467A5] hidden md:block'>
        <div
          className={CN('flex items-center container h-[52px] text-white justify-end', {
            'justify-between': isLoggedIn && !isSessionLoading
          })}>
          {/** Multi Side Ordering */}
          {isLoggedIn && !isSessionLoading && <div className='flex'>{MultiSideOrders}</div>}
          <div className='flex gap-x-2'>
            {/** promotion Drop down */}
            {Promotions}
            {/** Catalogues Drop Down */}
            {Catalogues}

            <div className='block lg:!hidden'>
              <Dropdown
                className='!min-w-[120px]'
                dropdownClassName='!z-10'
                disabled={false}
                aria-hidden='false'
                list={[
                  {
                    id: 0,
                    onClick: () => {
                      /** StoreLocation Page handle Tablet & Mobile */
                      if (handleStoreLocatorBtn) handleStoreLocatorBtn()
                    },
                    title: 'Store Locator'
                  },
                  {
                    id: 1,
                    onClick: function noRefCheck() {
                      /** Help Page handle Tablet & Mobile */
                      if (handleHelpBtn) handleHelpBtn()
                    },
                    title: 'Help'
                  },
                  {
                    id: 2,
                    onClick: function noRefCheck() {
                      /** About Page handle Tablet & Mobile */
                      if (handleAboutUsBtn) handleAboutUsBtn()
                    },
                    title: 'About Us'
                  }
                ]}
                toggle={{
                  // appearance: 'primary-deep-blue',
                  children: 'More',
                  className:
                    'focus:ring-offset-0 focus:ring-0 focus:shadow-none !bg-[#3467A5] hover:!bg-B-600',
                  size: 'xs',
                  componentId: 'top-bar-more'
                }}
              />
            </div>

            <div className='hidden lg:!flex gap-x-2'>
              {/** StoreLocation Page handle Desktop */}
              <Button
                componentId='top-bar-store-locator'
                className='focus:ring-offset-0 focus:ring-0 focus:shadow-none !bg-[#3467A5] hover:!bg-B-600'
                size='xs'
                onClick={handleStoreLocatorBtn}
                iconBefore='h-5 w-5 flex items-center justify-center nzsbi-location-pin'>
                Store Locator
              </Button>
              {/** Help Page handle Desktop */}
              <Button
                componentId='top-bar-help'
                className='focus:ring-offset-0 focus:ring-0 !bg-[#3467A5] hover:!bg-B-600'
                size='xs'
                onClick={handleHelpBtn}
                iconBefore='h-5 w-5 flex items-center justify-center nzsbi-question-double-lined-rounded'>
                Help
              </Button>
              {/** About Page handle Desktop */}
              <Link href='/about-us'>
                <Button
                  componentId='top-bar-about-us'
                  className='focus:ring-offset-0 focus:ring-0 focus:shadow-none !bg-[#3467A5] hover:!bg-B-600'
                  size='xs'
                  iconBefore='h-5 w-5 flex items-center justify-center nzsbi-info-squared'>
                  About Us
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/** Tablet and Mobile view page handle */}
      <div className='block lg:!hidden'>
        <AnimatePresence initial={false}>
          {expandSearch?.isOpen && (
            <motion.div
              className={CN('bg-B-500 md:!bg-[#3467A5]')}
              initial={'initial'}
              animate={'isOpen'}
              exit={'exit'}
              variants={{
                initial: { opacity: 0, height: 0 },
                isOpen: { opacity: 1, height: 'auto', transition: { duration: 0.2 } },
                exit: { opacity: 0, height: 0, transition: { duration: 0.2 } }
              }}>
              <div className={CN('container h-[52px] text-white items-center justify-center flex')}>
                {IntelligentSearch}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Headers
