/* eslint-disable import/no-extraneous-dependencies */
import Link from 'next/link'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IBrandLogoItem } from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface MegaMenuMostPopularBrandSectionProps {
  className?: string
  componentId?: string
  brandsData?: IBrandLogoItem[]
  hoverItemName?: string
  isLoading?: boolean
  mostPopularBrandItems: IBrandLogoItem[]
}

export const MegaMenuMostPopularBrandSection: FC<MegaMenuMostPopularBrandSectionProps> = ({
  className,
  componentId,
  brandsData,
  hoverItemName,
  isLoading = false,
  mostPopularBrandItems,
  ...restProps
}: MegaMenuMostPopularBrandSectionProps) => {
  const MegaMenuMostPopularBrandSectionClasses = CN(
    `${componentId} bg-N-25 rounded px-5 pt-2 pb-4 hidden md:block relative h-[144px]`,
    className
  )

  const { megaMenuModal } = useModalContext()

  return (
    <>
      {isLoading ? (
        <Skeleton
          data-component-id={`${componentId}-most-popular-brand-section-loader`}
          count={1}
          width={'100%'}
          height={144}
          containerClassName='flex flex-col'
        />
      ) : (
        <>
          {mostPopularBrandItems !== null && (
            <div className={MegaMenuMostPopularBrandSectionClasses} {...restProps}>
              <div className='flex justify-between'>
                {/** Most Popular Section Title */}
                {brandsData && (
                  <>
                    <span
                      data-component-id={`${componentId}-section-title`}
                      className='text-base font-700 text-N-800'>
                      {brandsData ? `Most Popular ${hoverItemName} Brands` : 'Most Popular Brands'}
                    </span>

                    {/** See all Btn */}
                    <Link
                      href='/brands/shop-by-brands'
                      onClick={() => {
                        megaMenuModal.close()
                      }}>
                      <Button
                        className='flex w-fit !justify-self-end self-end'
                        isBlock={false}
                        isRingOffset={false}
                        componentId={`${componentId}-see-all-btn`}
                        appearance='link-gray'>
                        See All
                      </Button>
                    </Link>
                  </>
                )}
              </div>

              {/** Most Popular Brand Items Section */}
              <div
                data-component-id={`${componentId}-list`}
                className='pt-2 flex gap-x-4 overflow-x-auto scrollbar-hide'>
                {mostPopularBrandItems?.map((item: IBrandLogoItem, index: number) => {
                  let url: any
                  let isInside: any
                  const linkToCuration = item?.attributes?.LinkToSearchCuration

                  if (linkToCuration) {
                    const urlProps = checkURL(item?.attributes?.SearchCurationURL)
                    isInside = urlProps?.isInsideUrl
                    url = urlProps?.originalUrl
                  } else {
                    // url = item?.attributes?.SearchCurationURL
                    isInside = true
                  }
                  return (
                    <Link
                      key={item.id}
                      passHref
                      target={isInside ? '' : '_blank'}
                      href={
                        linkToCuration
                          ? url
                          : `/brands/shop-by-brands/${JSON.parse(item?.attributes?.Brand)?.value}`
                      }
                      onClick={() => {
                        megaMenuModal.close()
                      }}
                      className={CN('cursor-pointer')}>
                      <div
                        key={item?.attributes?.BrandID}
                        className='border-[1px] border-N-50 rounded bg-white lg:w-[126px] lg:h-[88px] md:w-[88px] md:h-[72px] relative shadow-sm'>
                        <NextImage
                          nextImgStyle={{
                            padding: '16px',
                            objectFit: 'contain'
                          }}
                          data-component-id={`${componentId}-item-${index}`}
                          imgKey={`${item?.attributes?.BrandID}-${index}`}
                          imageUrl={
                            item?.attributes?.BrandLogo?.data?.attributes?.provider_metadata
                              ?.public_id
                          }
                          system='CMS'
                          useWithDimensions={false}
                          alt={item?.attributes?.BrandLogo?.data?.attributes?.alternativeText}
                          {...restProps}
                        />
                      </div>
                    </Link>
                  )
                })}

                <div className='absolute right-0 bg-gradient-to-l lg:bg-none from-[#F7F7F6] to-transparent w-[59px] h-[72px]'></div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MegaMenuMostPopularBrandSection
