import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'

export interface LinksProps {
  href?: string
  id: number
  label: string
  target?: string
}

export interface CopyrightProps {
  [x: string]: any
  className?: string
  companyName?: string
  links?: LinksProps[]
  year?: string
}

export const Copyright: FC<CopyrightProps> = ({
  className,
  companyName,
  links,
  year,
  ...restProps
}: CopyrightProps) => {
  const CopyrightClasses = CN(
    `copyright pt-[24px] pb-[24px] md:pb-[20px] flex flex-col justify-center w-full gap-x-[16px] lg:flex-row lg:items-center`,
    className
  )

  return (
    <div data-component-id='copyright' className={CopyrightClasses} {...restProps}>
      <div className='text-sm font-medium text-center text-white mb-[12px] md:flex md:justify-center md:gap-x-[16px] lg:mb-0'>
        <span className='block'>
          © {year} {companyName}
        </span>
        <nav className='flex gap-x-[16px] items-center justify-center'>
          {links &&
            links?.length > 0 &&
            links?.map((link: LinksProps) => {
              return (
                <Link
                  href={link?.href ?? '#'}
                  target={link?.target ?? '_self'}
                  key={link?.id}
                  className='text-sm text-B-200 hover:text-white'>
                  {link?.label}
                </Link>
              )
            })}
        </nav>
      </div>
    </div>
  )
}

export default Copyright
